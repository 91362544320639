import { put, call } from "@redux-saga/core/effects";

// utils
import { toaster } from "../../utils/toaster";

//constant
import { UserManagement, Auth, Intrests } from "../../themes/ReduxConstant";

// api calling methods
import {
  UserManagementUserList,
  UserDeleteApi,
  UserBlockApi,
  UserViewApi,
  UserEditApi,
  DropDownApi,
  InterestApi,
} from "../Api";

//user list
export function* userListSaga(action) {
  try {
    let data = yield call(UserManagementUserList, action.payload);
    if (data.status === 0) {
      yield put({
        type: UserManagement.ON_USER_SUCCES,
        payload: data.result,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_USER_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
//user delete
export function* userDeleteSaga(action) {
  try {
    let data = yield call(UserDeleteApi, { userId: action.payload.userId });
    if (data.status === 0) {
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_USER_DELETE_SUCCESS,
        payload: data.result,
      });
      yield put({
        type: UserManagement.ON_USER_LOAD,
        payload: action.payload.data,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_USER_DELETE_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {
  }
}
// user block
export function* userBlockSaga(action) {
  try {
    let data = yield call(UserBlockApi, action.payload.userId);
    if (data.status === 0) {
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_USER_BLOCK_SUCCESS,
        payload: data.result,
      });
      yield put({
        type: UserManagement.ON_USER_LOAD,
        payload: action.payload.data,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_USER_BLOCK_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
//user view
export function* userViewSaga(action) {
  try {
    let data = yield call(UserViewApi, action.payload.userId);
    if (data.status === 0) {
      yield put({
        type: UserManagement.ON_USER_VIEW_SUCCESS,
        payload: data.result,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      if (data.result?.data?.isDeleted === true) {
        action.payload.navigate("/usermanagement");
      }
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_USER_VIEW_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
export function* DropDownSaga(action) {
  try {
    let data = yield call(DropDownApi);
    if (data.status === 0) {
      yield put({
        type: UserManagement.ON_DROPDOWN_SUCCESS,
        payload: data.result,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_DROPDOWN_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {
  }
}
// user edit
export function* userEditSaga(action) {
  try {
    let data = yield call(UserEditApi, action.payload.data);
    if (data.status === 0) {
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_USER_EDIT_SUCCESS,
        payload: data.result,
      });
      action.payload.navigate("/usermanagement");
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      action.payload.navigate("/usermanagement");
      toaster(data?.result?.message);
      yield put({
        type: UserManagement.ON_USER_EDIT_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {
  }
}
export function* InterestsSaga(action) {
  try {
    let data = yield call(InterestApi);
    if (data.status === 0) {
      yield put({
        type: Intrests.INTRESTS_SUCCESS,
        payload: data.result.data,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: Intrests.INTRESTS_FAIL,
        payload: data.result,
      });
    }
  } catch (e) {
  }
}
