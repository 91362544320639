import React, { useState, useEffect } from "react";

//UI
import { Layout, Table, Breadcrumb } from "antd";

//routers
import { Link, useNavigate } from "react-router-dom";

//layouts
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";

//themes
import { appconstant } from "../../themes/appconstant";
import { columns } from "./constant";
import { RemovePaginationData } from "../../Redux/Actions/userManagmennt";
import {
  fandomQuizload,
  QuizTopicDeleteAction,
} from "../../Redux/Actions/FandomManagement";
import { useDispatch, useSelector } from "react-redux";
import { toaster } from "../../utils/toaster";
import { OnPagenationHandelChange, SearchBarFn } from "../../common";
const { Content } = Layout;

const instialData = {
  sortBy: "",
  order: "",
  search: "",
  offset: 0,
  limit: 10,
};

const FandomQuizManagement = () => {
  const [isVisible, setVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState(instialData);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = appconstant.Titles.FandomQuizManagement;
    return () => {
      dispatch(RemovePaginationData());
    };
  }, []);
  const reducerData = useSelector((state) => state.fandomMAnagementReducer);

  useEffect(() => {
    SearchBarFn(
      instialData,
      dispatch,
      fandomQuizload,
      setData,
      reducerData?.paginateData?.currentPage,
      searchInput,
      data
    );
  }, [searchInput]);
  const onButtonClick = (data) => {
    data?.name === appconstant.edit &&
      navigate("/fandomeditquiz", { state: data });
  };
  // this function dispatch action when user click on the delete button
  const cb = (isButtonClick, name, userData) => {
    isButtonClick &&
      dispatch(QuizTopicDeleteAction({ userId: userData._id, data: data }));
  };
  const handleChange = (pagination, filters, sorter) => {
    OnPagenationHandelChange(
      pagination,
      sorter,
      data,
      setData,
      dispatch,
      fandomQuizload
    );
  };

  const onAddQuizClick = () => {
    reducerData?.QuizList?.length > 0
      ? navigate("/fandomquiz")
      : toaster("No category is available.");
  };

  return (
    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">{appconstant.home}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{appconstant.fandomquizmanagement}</Breadcrumb.Item>
        </Breadcrumb>
        <Content className="new_layout_content">
          <div className="top_button_item">
            <button
              type="submit"
              className="button-list top_new"
              onClick={onAddQuizClick}
            >
              Add Quiz
            </button>
          </div>
          <div className="site-layout-background">
            <div className="content-e">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                <h6 className="text-white text-capitalize ps-3">
                  {appconstant.fandomquizmanagement}
                </h6>
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value.trimStart());
                  }}
                />
              </div>
              <Table
                dataSource={reducerData?.QuizList}
                columns={columns(
                  (isButtonClick, name, userData) =>
                    cb(isButtonClick, name, userData),
                  (data, name) => onButtonClick(data, name),
                  reducerData?.paginateData?.currentPage
                )}
                showSorterTooltip={false}
                onChange={handleChange}
                pagination={{
                  current: reducerData?.paginateData?.currentPage ?? 1,
                  total: reducerData?.paginateData?.totalCount,
                }}
                loading={reducerData.isLoading}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default FandomQuizManagement;
