import React, { useState, useEffect } from "react";

//UI
import { Layout, Table, Breadcrumb } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//routers
import { Link } from "react-router-dom";

//layouts
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";

//themes
import { appconstant } from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import {
  fandomQuizload,
  fandomQuizQuestionAddload,
  fandomQuizQuestionload,
  QuizQuestionDeleteAction,
  QuizQuestionEditAction,
} from "../../Redux/Actions/FandomManagement";
import { TabelHead } from "./constant";
import { RemovePaginationData } from "../../Redux/Actions/userManagmennt";
import QuestionModel from "./QuestionModel";
import { OnPagenationHandelChange, SearchBarFn } from "../../common";

const { Content } = Layout;
const instialData = {
  sortBy: "",
  order: "",
  search: "",
  offset: 0,
  limit: 10,
  fandomId: "",
};
const FandomQuizManagementAddQuiz = () => {
  const [AddClick, SetisAddClick] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState(instialData);
  const [ID, setID] = useState("");
  const dispatch = useDispatch();
  const reducerData = useSelector((state) => state.fandomMAnagementReducer);

  const [EditClick, SetisEditClick] = useState(false);
  const [QuestionData, setQuestionData] = useState(true);

  const [isVisible, setVisible] = useState(false);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  const [SelectedValue, setSelectedValue] = useState(
    reducerData?.QuizList?.[0]?.topic
  );
  useEffect(() => {
    document.title = appconstant.Titles.AddQuiz;
    dispatch(fandomQuizload({ offset: 0, limit: 200 }));
    setID(reducerData?.QuizList[0]?._id);
    return () => {
      dispatch(RemovePaginationData());
    };
  }, []);

  const onButtonClick = (data) => {
    SetisEditClick(true);
    setQuestionData({ ...data?.id });
  };

  const OnSelectButtonClick = (e) => {
    let index = reducerData?.QuizList?.findIndex(
      (item) => item?.topic === e?.target?.value
    );
    let id = reducerData?.QuizList[index]?._id;
    setID(id);
    setSelectedValue(e?.target?.value);
    data.offset = 0;
    data.fandomId = id;
    dispatch(fandomQuizQuestionload(data));
  };
  const QuestionCb = (value) => {
    SetisEditClick(value?.click);
    Object.keys(value?.data).length > 0 &&
      dispatch(QuizQuestionEditAction({ Questiondata: value, data: data }));
  };
  useEffect(() => {
    SearchBarFn(
      instialData,
      dispatch,
      fandomQuizQuestionload,
      setData,
      reducerData?.paginateData?.currentPage,
      searchInput,
      data,
      ID ? ID : reducerData?.QuizList[0]?._id
    );
  }, [searchInput]);
  // this function dispatch action when user click on the delete button
  const cb = (isButtonClick, name, userData) => {
    if (
      reducerData?.paginateQuizData?.totalCount - 1 ===
      (reducerData?.paginateQuizData?.currentPage - 1) * 10
    ) {
      data.offset = (reducerData?.paginateQuizData?.currentPage - 2) * 10;
    }
    isButtonClick &&
      dispatch(QuizQuestionDeleteAction({ userId: userData._id, data: data }));
  };
  const handleChange = (pagination, filters, sorter) => {
    OnPagenationHandelChange(
      pagination,
      sorter,
      data,
      setData,
      dispatch,
      fandomQuizQuestionload,
      ID
    );
  };
  const AddQuestionCb = (value) => {
    SetisAddClick(value?.click);
    Object.keys(value?.data).length > 0 &&
      dispatch(
        fandomQuizQuestionAddload({ Questiondata: value.data, data: data })
      );
  };
  return (
    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">{appconstant.home}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/fandom">{appconstant.fandomquizmanagement}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Add Quiz</Breadcrumb.Item>
        </Breadcrumb>
        <Content className="new_layout_content">
          <div className="form-group category">
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120, width: 200 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Select a Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="category"
                value={SelectedValue}
                onChange={OnSelectButtonClick}
              
                MenuProps={{
                  style: {
                    height: "300px",
                  },
                }}
              >
                {reducerData?.QuizList?.map((item) => {
                  return <MenuItem value={item.topic}>{item.topic}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
          <div className="top_button_item top_text">
            <button
              type="submit"
              className="button-list top_new "
              onClick={() => SetisAddClick(true)}
            >
              Add Question
            </button>
          </div>
          <p className="max">Max 50 questions can be added.</p>
          <div className="site-layout-background">
            <div className="content-e">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                <h6 className="text-white text-capitalize ps-3">
                  {" "}
                  {appconstant.fandomquizmanagement}
                </h6>
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value.trimStart());
                  }}
                />
              </div>
              <Table
                dataSource={reducerData.questionList}
                columns={TabelHead(
                  (isButtonClick, name, userData) =>
                    cb(isButtonClick, name, userData),
                  (data, name) => onButtonClick(data, name),
                  reducerData?.paginateQuizData?.currentPage,
                  true
                )}
                showSorterTooltip={false}
                onChange={handleChange}
                pagination={{
                  current: reducerData?.paginateQuizData?.currentPage ?? 1,
                  total: reducerData?.paginateQuizData?.totalCount,
                }}
                loading={reducerData?.isLoading}
              />
            </div>
          </div>
          {EditClick && (
            <QuestionModel
              cb={QuestionCb}
              isClick={EditClick}
              title={"Edit Question"}
              data={QuestionData}
            />
          )}
          {AddClick && (
            <QuestionModel
              cb={AddQuestionCb}
              isClick={AddClick}
              title={"Add Question"}
              data={{ ID: ID }}
              model={"Add"}
            />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
export default FandomQuizManagementAddQuiz;
