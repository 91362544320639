import { Model } from "./model";
import { Image } from "antd";

//CONSTANT
import { appconstant } from "../themes/appconstant";
import images from "../themes/appImage";
import { IMAGE_URL } from "../themes/ReduxConstant";

export const TabelHead = (cb, Userid, currentPage) => {
  return [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      width: "50px",
      render: (value, item, index) => {
        return (
          <div style={{ width: "50px" }}>
            {currentPage > 1 ? (currentPage - 1) * 10 + (index + 1) : index + 1}
          </div>
        );
      },
    },
    {
      title: "Profile Pic",
      dataIndex: "profileImage",
      key: "profileImage",
      sorter: false,
      // width: "75px",
      render: (value, item, index) => {
        return (
          <div style={{ width: "75px", maxWidth: "auto" }}>
            <Image
              src={
                item.profileImage !== null
                  ? IMAGE_URL + item.profileImage
                  : images.dummyUser
              }
              preview={false}
            />
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      // width: "200px",
      sorter: (a, b) => a.name - b.name,
      render: (value, item, index) => {
        return <div className="tabelColume">{item.name ?? "N/A"}</div>;
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: true,
      // width: "200px",
      sorter: (a, b) => a.gender - b.gender,
      render: (value, item, index) => {
        return <div className="tabelColume">{item.gender ?? "N/A"}</div>;
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      sorter: true,
      width: "200px",

      sorter: (a, b) => a.phone - b.phone,
      render: (value, item, index) => {
        return (
          <div className="tabelColume">
            {item.phone ? `${item.countryCode} ${item.phone}` : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item, index) => {
        return (
          <div key={index}>
            <button
              type="submit"
              onClick={() => Userid({ id: item._id, name: appconstant.view })}
            >
              {appconstant.view}
            </button>
            <button
              type="submit"
              onClick={() => Userid({ id: item, name: appconstant.edit })}
            >
              {appconstant.edit}
            </button>
            <button
              type="submit"
              onClick={() =>
                Model(
                  !item.isBlock
                    ? appconstant.block_Title
                    : appconstant.unBlock_Button,
                  !item.isBlock
                    ? appconstant.Block_Text
                    : appconstant.UnBlock_Text,
                  (isClick, name) => cb(isClick, name, item)
                )
              }
            >
              {!item.isBlock
                ? appconstant.block_Title
                : appconstant.unBlock_Button}
            </button>
            <button
              type="submit"
              onClick={() =>
                Model(
                  appconstant.Delete_Title,
                  appconstant.Delete_Text,
                  (isClick, name) => cb(isClick, name, item)
                )
              }
            >
              {appconstant.Delete_Title}
            </button>
          </div>
        );
      },
    },
  ];
};
