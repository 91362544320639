import React, { useState, useEffect, useRef } from "react";
//UI
import { Layout, Breadcrumb } from "antd";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Box, Chip, Select } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

//formik
import { Formik } from "formik";
//routers
import { Link, useLocation, useNavigate } from "react-router-dom";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  viewUserAction,
  dropdownListAction,
  editUserAction,
  RemovePaginationData,
} from "../../Redux/Actions/userManagmennt";

//layouts
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";

//commons
import { Selectfiled, Textfiled } from "../../common";
import {
  InteresteFn,
  OnAddMedia,
  OnAddMoviesMusic,
  ShowMusicVideo,
  ValuesTopicFn,
} from "./constant";
//themes
import {
  appconstant,
  Userheight,
  UserheightInch,
} from "../../themes/appconstant";
import images from "../../themes/appImage";
import { toaster } from "../../utils/toaster";
import IntrestsModels from "./IntrestsModels";
import { IMAGE_URL } from "../../themes/ReduxConstant";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { validationSchema } from "../../utils/ValidationSchema";
import TopicViewModel from "./TopicViewModel";
const { Content } = Layout;

let initialData = {
  name: "",
  gender: "",
  attractedTo: [],
  dob: "",
  education: "",
  employment: "",
  fitness: "",
  height: "",
  intentions: "",
  Religion: "",
  movies: [],
  music: [],
  maritalPrefrence: "",
  parentalPrefrence: "",
  bio: "",
  vices: {},
  foot: "",
  inch: "",
  other: "",
  countryCode: "",
  city: "",
  otherReligion: "",
};
const EditUser = () => {
  const [Data, setdata] = useState(initialData);
  const [isVisible, setVisible] = useState(false);
  const [isModelClick, setIsModelClick] = useState({
    iSInterstsClick: false,
    topic: false,
    values: false,
  });
  const [Interests, setInterests] = useState({});
  const [PreSelectesImges, setPreSelectesImges] = useState([]);
  const [Blob, setBlob] = useState([]);
  const [PreSelectesVideo, setPreSelectesVideo] = useState([]);
  const [VideoBlob, setVideoBlob] = useState([]);
  const [removeMedia, setremoveMedia] = useState([]);
  const [MediaLength, setMediaLength] = useState(null);
  const [VideoLength, setVideoLength] = useState(null);
  const [imageLength, setImageLength] = useState(null);

  const [IntrestsData, setIntrestsData] = useState({});
  const [TopicsData, setTopicsData] = useState([]);
  const [ValuesData, setValuesData] = useState([]);
  const [dob, setDate] = useState({});
  const [counts, setCounts] = useState(0);

  const [MusicText, setMusicText] = useState("");
  const [MovieText, setMoviesText] = useState("");
  const [Music, setMusic] = useState([]);
  const [Movies, setMovies] = useState([]);

  const [otherGenderSeleted, setOtherGenderSeleted] = useState(false);
  const [otherReligionSeleted, setOtherReligionSeleted] = useState(false);

  const { userData, dropdown } = useSelector(
    (state) => state.userManagmenntReducer
  );
  const InputRef = useRef();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlewClick = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    document.title = appconstant.Titles.EditUser;
    if (state?.id?._id) {
      dispatch(viewUserAction({ userId: state?.id?._id, navigate: navigate }));
      dispatch(dropdownListAction());
    } else {
      navigate("/usermanagement");
    }
    return () => {
      dispatch(RemovePaginationData());
      Data.offset = 0;
    };
  }, []);

  function toFeet(n) {
    let feet = (n / 12).toString().split(".");
    var inch = (n % 12).toString().split(".");
    return [feet[0], inch[0]];
  }

  const musicMovie = (values) => {
    let arr = [];
    if (values) {
      for (let i of values) {
        let obj = {};
        obj.value = i;
        obj.id = Math.random().toString();
        arr.push(obj);
      }
    }
    return arr;
  };

  let height;
  if (userData?.depthInfo?.height) {
    height = toFeet(userData?.depthInfo?.height);
  } else {
    height = [3, 0];
  }

  useEffect(() => {
    let music = musicMovie(userData?.depthInfo?.music);
    let movie = musicMovie(userData?.depthInfo?.movies);
    setMusic(music);
    setMovies(movie);
    let data = initialData;
    data.name = userData?.info?.name ?? "";
    if (userData?.depthInfo?.religion) {
      let isExistedInList = Boolean(
        dropdown?.religion?.find(
          (item) => item == userData?.depthInfo?.religion
        )
      );
      if (!isExistedInList) {
        data.Religion = "Other";
        data.otherReligion = userData?.depthInfo?.religion ?? "";
        setOtherReligionSeleted(true);
      } else {
        setOtherReligionSeleted(false);
        data.Religion = userData?.depthInfo?.religion;
        data.otherReligion = "";
      }
    }

    if (userData?.info?.gender) {
      if (
        userData?.info?.gender !== "Male" &&
        userData?.info?.gender !== "Female"
      ) {
        data.gender = "Other";
        data.other = userData?.info?.gender ? userData?.info?.gender : "";
        setOtherGenderSeleted(true);
      } else {
        setOtherGenderSeleted(false);
        data.gender = userData?.info?.gender;
        data.other = "";
      }
    }
    data.phone = userData?.phone ?? "";
    data.attractedTo = userData?.info?.attractedTo ?? [];
    data.education = userData?.depthInfo?.education ?? "";
    data.employment = userData?.depthInfo?.employment ?? "";
    data.fitness = userData?.depthInfo?.fitness ?? "";
    data.intentions = userData?.depthInfo?.intentions ?? "";
    data.movies = userData?.depthInfo?.movies ?? [];
    data.music = userData?.depthInfo?.music ?? [];
    data.maritalPrefrence = userData?.depthInfo?.preference?.marriage ?? "";
    data.parentalPrefrence = userData?.depthInfo?.preference?.child ?? "";
    data.bio = userData?.depthInfo?.bio ?? "";
    data.smoking = userData?.depthInfo?.vices.smoking ?? "";
    data.gambling = userData?.depthInfo?.vices.gambling ?? "";
    data.drinking = userData?.depthInfo?.vices.drinking ?? "";
    data.inch = height[1] === 0 ? 0 : height[1];
    data.foot = height[0] === 0 ? 3 : height[0];
    data.dob = setDate(userData?.info?.dob ?? "");
    data.city = userData?.depthInfo?.city ?? "";
    setdata(data);
    setTopicsData(userData?.depthInfo?.topics);
    setValuesData(userData?.depthInfo?.values);
    let isImage = userData?.info?.media?.filter((item) => item.isImage);
    let isVideo = userData?.info?.media?.filter((item) => item?.isVideo);
    setPreSelectesImges(isImage);
    setPreSelectesVideo(isVideo);
  }, [userData]);

  useEffect(() => {
    Object.keys(Interests).length === 0
      ? setIntrestsData(userData?.depthInfo?.interests)
      : setIntrestsData(Interests);
  }, [Interests, userData]);

  const dd = (value) => {
    let arr = [];
    for (let i of value) {
      arr.push(i.value);
    }
    return arr;
  };

  const onSubmit = (e) => {
    if (MediaLength !== 0) {
      let music = dd(Music);
      let movie = dd(Movies);
      let newArr = [...Blob, ...VideoBlob];
      let height = +(e.foot * 12) + +e.inch;
      let formdata = new FormData();
      formdata.append("userId", state?.id?._id);
      e?.name && formdata.append("name", e?.name);
      formdata.append("gender", e?.gender === "Other" ? e?.other : e?.gender);
      e?.phone !== "N/A" && formdata.append("phone", e?.phone);
      formdata.append("dob", dob);
      e?.education && formdata.append("education", e?.education);
      e?.employment && formdata.append("employment", e?.employment);
      formdata.append("fitness", e?.fitness);
      formdata.append("attractedTo", JSON.stringify(e?.attractedTo));
      formdata.append("height", height);
      formdata.append("intentions", e?.intentions);
      formdata.append(
        "religion",
        e?.Religion === "Other" ? e?.otherReligion : e?.Religion
      );
      formdata.append("city", e?.city);
      e?.maritalPrefrence &&
        formdata.append("maritalPrefrence", e?.maritalPrefrence);
      e?.parentalPrefrence &&
        formdata.append("parentalPrefrence", e?.parentalPrefrence);
      formdata.append("bio", e?.bio);
      formdata.append("drinking", e?.drinking);
      formdata.append("smoking", e?.smoking);
      formdata.append("gambling", e?.gambling);
      IntrestsData &&
        formdata.append("interests", JSON.stringify(IntrestsData));
      TopicsData && formdata.append("topics", JSON.stringify(TopicsData));
      ValuesData && formdata.append("values", JSON.stringify(ValuesData));
      formdata.append("removeMedia", JSON.stringify(removeMedia));
      formdata.append("music", JSON.stringify(music));
      formdata.append("movies", JSON.stringify(movie));
      for (let index = 0; index < newArr.length; index++) {
        formdata.append("addMedia", newArr[index]?.Url);
      }
      dispatch(editUserAction({ data: formdata, navigate: navigate }));
    } else {
      toaster("Please upload at least one media.");
    }
  };

  const onInterestCb = (value, key) => {
    if (key === "Interests")
      setIsModelClick({
        ...isModelClick,
        iSInterstsClick: value,
      });
    if (key === "topic") {
      setIsModelClick({ ...isModelClick, topic: value });
    }
    if (key === "values") {
      setIsModelClick({ ...isModelClick, values: value });
    }
  };

  const SelectedInterestCb = (value, key) => {
    if (key === "Interests") {
      setInterests({ ...value });
    }
    if (key === "topic") {
      setTopicsData([...value]);
    }
    if (key === "values") {
      setValuesData([...value]);
    }
  };
  //on cancel click
  const onMediaCancelClick = (index, preSelected, type, id) => {
    if (preSelected && type === "image") {
      let Image = [...PreSelectesImges];
      Image.splice(index, 1);
      setPreSelectesImges(Image);
      setremoveMedia([...removeMedia, id]);
    } else if (type === "image" && preSelected === false) {
      let Image = [...Blob];
      let imageIndex = Image?.indexOf((item) => item.Id === index);
      Image.splice(imageIndex, 1);
      setBlob(Image);
    } else if (type === "video" && preSelected) {
      let video = [...PreSelectesVideo];
      video.splice(index, 1);
      setPreSelectesVideo(video);
      setremoveMedia([...removeMedia, id]);
    } else if (type === "video" && preSelected === false) {
      let video = [...VideoBlob];
      let videoIndex = video?.indexOf((item) => item.Id === index);
      video.splice(videoIndex, 1);
      setVideoBlob(video);
    }
  };
  useEffect(() => {
    let medialenght =
      userData?.info?.media?.length +
      Blob?.length +
      VideoBlob?.length -
      removeMedia?.length;
    setMediaLength(medialenght);
    let imageLength = PreSelectesImges?.length + Blob?.length;
    let VideoLength = PreSelectesVideo?.length + VideoBlob?.length;
    setVideoLength(VideoLength);
    setImageLength(imageLength);
  }, [OnAddMedia, onMediaCancelClick]);
  // video
  const ShowVideo = (preSelected, data) => {
    return data?.map((item, index) => {
      return (
        <div className="video-div" key={Math.random().toString()}>
          <video controls width={"100%"} height={"100%"}>
            {preSelected ? (
              <source src={IMAGE_URL + item.fileName} type="video/mp4" />
            ) : (
              <source src={item?.blob} type="video/mp4" />
            )}
          </video>
          <img
            src={images.CrossImg}
            className="cross-i"
            onClick={() =>
              preSelected
                ? onMediaCancelClick(index, true, "video", item?._id)
                : onMediaCancelClick(item?.Id, false, "video")
            }
            alt=""
            title="Click to remove image."
          />
        </div>
      );
    });
  };
  //images
  const ShowImages = (data, preselected) =>
    data?.map((item, index) => {
      return (
        <>
          <div className="iamge-t" key={Math.random().toString()}>
            {preselected ? (
              <img
                src={IMAGE_URL + item.fileName}
                width={"100%"}
                height={"100%"}
                alt=""
              />
            ) : (
              <img src={item?.blob} width={"100%"} height={"100%"} alt="" />
            )}
            <img
              src={images.CrossImg}
              className="cross-i"
              onClick={() =>
                preselected
                  ? onMediaCancelClick(index, true, "image", item?._id)
                  : onMediaCancelClick(item?.Id, false, "image")
              }
              alt=""
            />
          </div>
        </>
      );
    });

  useEffect(() => {
    let count = 0;
    IntrestsData &&
      Object.values(IntrestsData)?.forEach((item) => {
        if (item.length > 0) {
          count += item;
        }
      });
    setCounts(count);
  }, [IntrestsData]);

  useEffect(() => {
    let gender = InputRef.current?.values?.gender;
    let religion = InputRef.current?.values?.Religion;
    if (gender !== "Other") {
      InputRef.current.setFieldValue("other", "");
      InputRef.current.setTouched("other", false);
      InputRef.current.setErrors("other", false);
      setOtherGenderSeleted(false);
    }
    if (religion !== "Other") {
      InputRef.current.setFieldValue("otherReligion", "");
      InputRef.current.setTouched("otherReligion", false);
      InputRef.current.setErrors("otherReligion", false);
      setOtherReligionSeleted(false);
    }
  }, [otherGenderSeleted, otherReligionSeleted]);

  return (
    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">{appconstant.home}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/usermanagement">{appconstant.UserManagement}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{appconstant.edituserdetails}</Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <div className="content-e">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">
                  {appconstant.edituserdetails}
                </h6>
              </div>
              <div className="wrapper_line">
                <Formik
                  initialValues={Data}
                  onSubmit={onSubmit}
                  enableReinitialize
                  validationSchema={validationSchema(
                    "EditUser",
                    otherGenderSeleted,
                    otherReligionSeleted
                  )}
                  innerRef={InputRef}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    touched,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <Textfiled
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          label="Full Name"
                          variant="standard"
                          className="filed-control"
                          type="text"
                          name="name"
                          value={values?.name?.trimStart()}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("name", newData);
                          }}
                          textLength={50}
                        />
                      </div>
                      <div className="form-group">
                        <Textfiled
                          label={"Phone"}
                          disabel={true}
                          variant="standard"
                          className="filed-control"
                          name="phone"
                          value={
                            values?.phone
                              ? `${userData?.countryCode} ${values?.phone}`
                              : "N/A"
                          }
                        />
                      </div>
                      <Selectfiled
                        error={touched.gender && errors?.gender}
                        id="demo-simple-select-standard"
                        name="gender"
                        label={"Gender Identity"}
                        onChange={(e) => {
                          setOtherGenderSeleted(e.target.value === "Other");
                          handleChange(e);
                        }}
                        value={values?.gender ?? ""}
                        dropdown={dropdown?.gender}
                        isGender={true}
                      />

                      {values?.gender === "Other" && (
                        <div
                          className="form-group"
                          style={{
                            display: `${values?.gender !== "Other" && "none"}`,
                          }}
                        >
                          <Textfiled
                            error={Boolean(touched.other && errors.other)}
                            helperText={touched.other && errors.other}
                            variant="standard"
                            className="filed-control"
                            name="other"
                            placeholder="Please enter gender"
                            value={values?.other?.trimStart() ?? ""}
                            onChangeText={(e) => {
                              const newData = e.target.value.trimStart();
                              setFieldValue("other", newData);
                            }}
                            textLength={20}
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <FormControl
                          variant="standard"
                          id="demo-multiple-chip"
                          sx={{ m: 1, width: 300 }}
                          className="TextField"
                        >
                          <InputLabel id="demo-multiple-chip-label">
                            Attracted To
                          </InputLabel>
                          <Select
                            id="demo-multiple-chip"
                            multiple
                            variant="standard"
                            value={values.attractedTo ?? []}
                            onChange={handleChange}
                            name="attractedTo"
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected?.map((value) => (
                                  <Chip
                                    key={value}
                                    label={
                                      value === "Other" ? "Non-binary" : value
                                    }
                                  />
                                ))}
                              </Box>
                            )}
                          >
                            {dropdown?.attractedTo?.map((name) => (
                              <MenuItem key={name} value={name}>
                                {name === "Other" ? "Non-binary" : name}
                              </MenuItem>
                            ))}
                          </Select>
                        
                        </FormControl>
                        <div >
                        {Boolean(touched.attractedTo && errors.attractedTo) && (
                            <FormHelperText
                              style={{ color: "#d32f2f", height: "0px" }}
                            >
                              {errors.attractedTo}
                            </FormHelperText>
                          )} 
                        </div>
                        
                      </div>
                      <div className="form-group time-picker">
                        <InputLabel id="demo-simple-select-standard-label">
                          {"Date of Birth"}
                        </InputLabel>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          className={"datePicker datePickerDiv DatePicker"}
                        >
                          <MobileDatePicker
                            maxDate={new Date().setFullYear(
                              new Date().getFullYear() - 18
                            )}
                            inputFormat="dd/MM/yyyy"
                            id="standard-basic"
                            value={dob}
                            onChange={(e) => setDate(e)}
                            name="dob"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            renderInput={(params) => {
                              return (
                                <div
                                  className="DatePicekerInput"
                                  style={{
                                    position: "relative",
                                    borderBottom:
                                      "1px solid rgba(0, 0, 0, 0.85)",
                                  }}
                                >
                                  <TextField {...params} />
                                  <icon
                                    onClick={() => params.inputProps.onClick()}
                                    className={"DateIcon"}
                                  >
                                    <MdDateRange size={25} />
                                  </icon>
                                </div>
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </div>

                      <div>
                        <InputLabel>Height</InputLabel>
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "13px",
                            width: "100%",
                          }}
                        >
                          <div className="heightDiv">
                            <Selectfiled
                              name={"foot"}
                              label={"Feet(ft)"}
                              onChange={(e) => {
                                setFieldValue("foot", e.target.value);
                                if (e.target.value == "9") {
                                  setFieldValue("inch", "0");
                                }
                              }}
                              value={values.foot}
                              dropdown={Userheight}
                              className="Height"
                            />
                          </div>
                          <div className="heightDiv">
                            <Selectfiled
                              name={"inch"}
                              label={"Inch(in)"}
                              onChange={handleChange}
                              value={values.foot == 9 ? 0 : values?.inch}
                              dropdown={
                                values.foot == 9 ? ["0"] : UserheightInch
                              }
                              className="Height"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <Textfiled
                          label="City Of Residence"
                          variant="standard"
                          className="filed-control"
                          type="text"
                          name="city"
                          value={values?.city?.trimStart()}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("city", newData);
                          }}
                          textLength={50}
                        />
                      </div>
                      <Selectfiled
                        // error={errors?.fitness}
                        name={"fitness"}
                        label={"Fitness"}
                        onChange={handleChange}
                        value={values.fitness}
                        dropdown={dropdown?.fitness}
                      />

                      <Selectfiled
                        // error={errors?.Religion}
                        name={"Religion"}
                        label={"Religion"}
                        onChange={(e) => {
                          setOtherReligionSeleted(e.target.value === "Other");
                          handleChange(e);
                        }}
                        value={values?.Religion}
                        dropdown={dropdown?.religion}
                      />
                      <div
                        className="form-group"
                        style={{
                          display: `${
                            values?.Religion == "Other" ? "" : "none"
                          }`,
                        }}
                      >
                        <Textfiled
                          variant="standard"
                          className="filed-control"
                          name="otherReligion"
                          placeholder="Please enter religion"
                          value={values?.otherReligion?.trimStart() ?? ""}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("otherReligion", newData);
                          }}
                          textLength={20}
                          error={Boolean(
                            touched.otherReligion && errors.otherReligion
                          )}
                          helperText={
                            touched.otherReligion && errors.otherReligion
                          }
                        />
                      </div>

                      <div>
                        <InputLabel>Vices</InputLabel>
                        <div className="View_Vice">
                          <Selectfiled
                            outerDiv={"outerDiv"}
                            name={"drinking"}
                            label={"Drinking"}
                            onChange={handleChange}
                            value={values?.drinking}
                            dropdown={dropdown?.vices}
                            className="EditUser_Inner_vices_div Height"
                          />

                          <Selectfiled
                            outerDiv={"outerDiv"}
                            name={"gambling"}
                            label={"Gambling"}
                            onChange={handleChange}
                            value={values?.gambling}
                            dropdown={dropdown?.vices}
                            className="EditUser_Inner_vices_div Height"
                          />

                          <Selectfiled
                            outerDiv={"outerDiv"}
                            name={"smoking"}
                            label={"Smoking"}
                            onChange={handleChange}
                            value={values?.smoking}
                            dropdown={dropdown?.vices}
                            className="EditUser_Inner_vices_div"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <Textfiled
                          // error={Boolean(touched.education && errors.education)}
                          // helperText={touched.education && errors.education}
                          label="Education"
                          value={values.education?.trimStart()}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("education", newData);
                          }}
                          name="education"
                          textLength={50}
                        />
                      </div>

                      <div className="form-group">
                        <Textfiled
                          label="Occupation"
                          value={values.employment?.trimStart()}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("employment", newData);
                          }}
                          name="employment"
                          textLength={50}
                        />
                      </div>

                      <div>
                        <div className="MusicDiv">
                          <TextField
                            variant="standard"
                            className="filed-control TextField"
                            id="filled-basic"
                            label="Music"
                            name="music"
                            value={MusicText.trimStart()}
                            onChange={(e) => setMusicText(e.target.value)}
                            inputProps={{ maxLength: 40 }}
                          />
                          <span
                            className={"iconDiv"}
                            onClick={() =>
                              OnAddMoviesMusic(
                                Music,
                                MusicText,
                                setMusic,
                                appconstant?.AddMusic,
                                setMusicText
                              )
                            }
                          >
                            <AiOutlinePlusCircle
                              color={`${
                                MusicText?.length > 0
                                  ? "rgb(122 142 215)"
                                  : "lightgray  "
                              }`}
                              size={25}
                              cursor="pointer"
                              title={"Click to add music"}
                            />
                          </span>
                        </div>
                        <div>
                          {ShowMusicVideo(
                            Music,
                            "Click to remove music",
                            Music,
                            setMusic
                          )}
                        </div>
                      </div>

                      <div>
                        <div className="MusicDiv">
                          <TextField
                            variant="standard"
                            className="filed-control TextField"
                            id="filled-basic"
                            label="Movies"
                            name="movies"
                            value={MovieText.trimStart()}
                            onChange={(e) => setMoviesText(e.target.value)}
                            inputProps={{ maxLength: 40 }}
                          />
                          <span
                            className={"iconDiv"}
                            onClick={() =>
                              OnAddMoviesMusic(
                                Movies,
                                MovieText,
                                setMovies,
                                appconstant?.addMovies,
                                setMoviesText
                              )
                            }
                          >
                            <AiOutlinePlusCircle
                              color={`${
                                MovieText?.length > 0
                                  ? "rgb(122 142 215)"
                                  : "lightgray  "
                              }`}
                              size={25}
                              cursor="pointer"
                              title={"Click to add movie"}
                            />
                          </span>
                        </div>
                        <div>
                          {ShowMusicVideo(
                            Movies,
                            "Click to remove movie",
                            Movies,
                            setMovies
                          )}
                        </div>
                      </div>

                      <Selectfiled
                        name={"intentions"}
                        label={"Intentions"}
                        onChange={handleChange}
                        value={values?.intentions}
                        dropdown={dropdown?.intentions}
                      />

                      <Selectfiled
                        name={"maritalPrefrence"}
                        label={" Marriage Preference "}
                        onChange={handleChange}
                        value={values?.maritalPrefrence?.trimStart()}
                        dropdown={dropdown?.maritalPrefrence}
                      />

                      <Selectfiled
                        name={"parentalPrefrence"}
                        label={"Child Preference"}
                        onChange={handleChange}
                        value={values?.parentalPrefrence.trimStart()}
                        dropdown={dropdown?.parentalPrefrence}
                      />

                      <div className="form-control">
                        <Textfiled
                          id="standard-multiline-static"
                          label="Bio"
                          multiline
                          rows={4}
                          variant="standard"
                          value={values.bio.trimStart()}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("bio", newData);
                          }}
                          name="bio"
                          textLength={1000}
                        />
                      </div>

                      <div className="InterestsMainDiv">
                        <div className="InterestsLabel">
                          <InputLabel>Interests (Any 5)</InputLabel>
                          <div
                            onClick={() => {
                              setIsModelClick({
                                ...isModelClick,
                                iSInterstsClick: true,
                              });
                            }}
                          >
                            <AiOutlinePlusCircle
                              color="#939de5"
                              size={28}
                              cursor="pointer"
                              title={"Click to add interests"}
                            />
                          </div>
                        </div>
                        <label className="NoValueLabel">
                          {counts === 0 ? "No interest is selected." : ""}
                        </label>
                        <div>{IntrestsData && InteresteFn(IntrestsData)}</div>
                      </div>
                      <div className="InterestsMainDiv">
                        <div className="InterestsLabel">
                          <InputLabel>Values (Any 5)</InputLabel>
                          <div
                            onClick={() => {
                              setIsModelClick({
                                ...isModelClick,
                                values: true,
                              });
                            }}
                          >
                            <AiOutlinePlusCircle
                              color="#939de5"
                              size={28}
                              cursor="pointer"
                              title={"Click to add values"}
                            />
                          </div>
                        </div>
                        <label className="NoValueLabel">
                          {ValuesData?.length == 0
                            ? "No values is selected."
                            : ""}
                        </label>
                        <div>{ValuesData && ValuesTopicFn(ValuesData)}</div>
                      </div>

                      <div>
                        <div className="InterestsLabel">
                          <p className="option_lite f">Photos</p>
                          <div>
                            <label htmlFor="image">
                              <AiOutlinePlusCircle
                                color="#939de5"
                                size={28}
                                title={"Click to add image"}
                                cursor="pointer"
                              />
                            </label>
                            <input
                              type="file"
                              id="image"
                              onChange={(e) =>
                                OnAddMedia(e, "image", setBlob, MediaLength)
                              }
                              style={{ display: "none" }}
                              accept="image/*"
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                            />
                          </div>
                        </div>
                        <label className="NoValueLabel">
                          {imageLength === 0 ? "No image is uploaded." : ""}
                        </label>
                        <div className="wrapper_multi">
                          {ShowImages(PreSelectesImges, true)}
                          {ShowImages(Blob, false)}
                        </div>
                      </div>

                      <div>
                        <div className="InterestsLabel">
                          <p className="option_lite f">Videos</p>
                          <div>
                            <label htmlFor="video">
                              <AiOutlinePlusCircle
                                color="#939de5"
                                size={28}
                                cursor="pointer"
                                title={"Click to add video"}
                              />
                            </label>
                            <input
                              type="file"
                              id="video"
                              onChange={(e) =>
                                OnAddMedia(
                                  e,
                                  "video",
                                  setVideoBlob,
                                  MediaLength
                                )
                              }
                              style={{ display: "none" }}
                              accept="video/*"
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                            />
                          </div>
                        </div>
                        <label className="NoValueLabel">
                          {VideoLength === 0 ? "No video is uploaded." : ""}
                        </label>
                        <div className="wrapper_multi">
                          {ShowVideo(true, PreSelectesVideo)}
                          {ShowVideo(false, VideoBlob)}
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="button-list EditButton"
                          onClick={() => {
                            if (errors) {
                              window.scrollTo(0, 100);
                            }
                          }}
                        >
                          {appconstant.update}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {isModelClick?.values && (
            <TopicViewModel
              isVisibal={isModelClick?.values}
              InterestCb={onInterestCb}
              PreSelectedInterests={ValuesData}
              SelectedInterestCb={SelectedInterestCb}
              SelectedKey={"values"}
              heading={"Values (Any 5)"}
              totalLenght={5}
            />
          )}
          {isModelClick?.iSInterstsClick && (
            <IntrestsModels
              isVisibal={isModelClick?.iSInterstsClick}
              InterestCb={onInterestCb}
              PreSelectedInterests={
                Object.keys(Interests).length === 0
                  ? userData?.depthInfo?.interests
                  : IntrestsData
              }
              SelectedInterestCb={SelectedInterestCb}
              SelectedKey={"Interests"}
            />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
export default EditUser;
