import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

export const Textfiled = (props) => {
  const {
    type,
    helperText,
    error,
    name,
    label,
    onChangeText,
    value,
    textLength,
    Require,
    disabel,
    placeholder,
  } = props;
  return (
    <TextField
      id="filled-basic"
      className="filed-control TextField"
      error={error}
      helperText={helperText}
      label={label}
      variant="standard"
      type={type}
      value={value}
      onChange={onChangeText}
      name={name}
      inputProps={{ maxLength: textLength }}
      required={Require}
      disabled={disabel}
      placeholder={placeholder}
    />
  );
};
export const Selectfiled = (props) => {
  const {
    error,
    name,
    label,
    onChange,
    value,
    dropdown,
    className,
    outerDiv,
    Require,
    placeholder,
    isGender
  } = props;
  return (
    <div className={`form-group ${outerDiv}`}>
      <FormControl
        variant="standard"
        sx={{ m: 1, minWidth: 120 }}
        className="TextField"
      >
        <InputLabel
          id="demo-simple-select-standard-label"
          className={className}
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
          onChange={onChange}
          name={name}
          required={Require}
          placeholder={placeholder}
        >
          {dropdown &&
            dropdown?.map((item) => (
              <MenuItem value={item}>
                {isGender ? (item === "Other" ? "Non-binary" : item) : item}
              </MenuItem>
            ))}
        </Select>
        {error && (
          <FormHelperText style={{ color: "#d32f2f", height: "0px" }}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

//this function is use to dispatch action when user type in search bar .
export const SearchBarFn = (
  instialData,
  dispatch,
  action,
  SetState,
  currentPage,
  searchInput,
  data,
  id
) => {
  let newData = instialData;
  newData.search = searchInput;
  newData.offset =
    searchInput?.length === 0 ? (currentPage ? (currentPage - 1) * 10 : 0) : 0;
  if (newData?.hasOwnProperty("fandomId")) {
    newData.fandomId = id;
  }
  dispatch(action(newData));
  SetState(newData);
};

// this function calls when the user click on the sorting icon or the change the page
export const OnPagenationHandelChange = (
  pagination,
  sorter,
  data,
  setData,
  dispatch,
  action
) => {
  window.scrollTo(0, 0);
  let order =
    sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : "";
  const newData = {
    sortBy: sorter.field,
    order: order,
    search: data.search,
    offset: (pagination.current - 1) * 10,
    limit: 10,
  };
  if (data.hasOwnProperty("fandomId")) {
    newData.fandomId = data.fandomId;
  }
  dispatch(action(newData));
  setData(newData);
};
