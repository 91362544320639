import images from "./appImage";

export const appconstant = {
  fullName: "Full Name",
  changepassword: "Change Password",
  Oldpassword: "Old Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  contactus: "Contact us",
  home: "Home",
  viewmessage: "View Message",
  EmailAddress: "Email Address",
  GroupMessages: "Group Messages ",
  IndividualMessages: "Individual Messages",
  UserManagement: "User Management",
  view: "View",
  delete: "Delete",
  edit: "Edit",
  block_Title: "Block",
  Delete_Title: "Delete",
  Delete_Text: "Are you sure, you want to delete this user?",
  Block_Text: "Are you sure, you want to block this user?",
  UnBlock_Text: "Are you sure, you want to unblock this user?",
  AddMusic: "You can only add up to 5 music.",
  addMovies: "You can only add up to 5 movies.",
  QuizDelete_Text:
    "Are you sure, you want to delete all the questions of this fandom?",
  unBlock_Button: "Unblock",
  update: "Update",
  export: "Export",
  add: "Add",
  Password: "Password",
  ConfrmPassword: "Confrm Password",

  UserDetails: "User Details",
  Userid: "User ID",
  UserName: "User Name",
  DateofJoining: "Date of Joining",
  GameOrganized: "Game Organized",
  gamesplayed: "Game Played",
  edituserdetails: "Edit User Details",
  fandomquizmanagement: "Fandom Quiz Management",
  addnewvoucher: "Add New Voucher",
  AddNewUser: "Add New User",
  CurrentlyHosting: "Currently Hosting",

  //
  prelistedwords: "Pre-Listed Words",
  InApppurchaseManagement: "In-App Purchase Management ",
  //
  EditAboutUs: "Edit About Us",
  EditPrivacyPolicy: "Edit Privacy Policy",
  AboutUs: "About Us",
  GameSettings: "Game Settings",
  PrivacyPolicy: "Privacy Policy",
  ContactInfo: "Contact Info",
  FAQs: "FAQs",
  EditContactInfo: "Edit Contact Info",
  AddNewFAQ: "Add New FAQ",
  phonenumber: "Phone Number",
  submit: "Submit",
  GamePlayed: "Game Played",
  cancel: "Cancel",
  Photos: "Photos",
  Lastname: "Last name ",
  Gender: "Gender Identity",
  PhoneNumber: "Phone Number",
  Genderprefrence: "Attracted To",
  Dateofbirth: "Date of Birth",
  Height: "Height",
  Build: "Fitness",
  Religion: "Religion",
  Interests: "Interests",
  Vices: "Vices",
  Education: "Education",
  Employment: "Occupation",
  Music: "Music",
  Movies: "Movies",
  Datingprefrence: "Intentions",
  City: "City Of Residence",
  Marriageprefrence: "Marriage Preference",
  Childprefrence: "Child Preference ",
  Location: "Location",
  Bio: "Bio",
  LuvBux: "LuvBux",
  Video: "Videos",
  buttonupate: "Update",
  toastTimer: 1500,
  Titles: {
    changepassword: "SparkDating - Change password",
    Dashboard: "SparkDating - Dashboard",
    UserManagement: "SparkDating - User Management",
    FandomQuizManagement: "SparkDating - Fandom Quiz Managment",
    InApppurchaseManagement: "SparkDating - In App purchase Management",
    Login: "SparkDating - Login",
    ForgotPassword: "SparkDating - Forgot Password",
    ResetPassword: "SparkDating - Reset Password",
    AddQuiz: "SparkDating - Add Quiz",
    EditQuiz: "SparkDating - Edit Quiz",
    AddUser: "SparkDating - Add User",
    ViewUser: "SparkDating - View User",
    EditUser: "SparkDating - Edit User Details",
  },
  Delete_QUESTION_text: "Are you sure, you want to delete this question?",
  toastId: "toastId",
};

export const validation = {
  EMAIL_REQUIRED: "Please enter the email address.",
  EMAIL_ERROR: "Please enter a valid email address.",
  PASSWORD_REQUIRED: "Please enter password.",
  NEW_PASS_REQ: "Please enter new password.",
  OLD_PASS_REQ: "Please enter old password.",
  PASSWORD_NEW_REQUIRED: "Please enter new password.",
  PASSWORD_ERROR:
    "Password must include at least 1 lowercase, 1 uppercase, 1 number and 1 special characters.",
  New_PASSWORD_ERROR:
    "New password must include at least 1 lowercase, 1 uppercase, 1 number and 1 special characters.",
  PASSWORD_LENGTH: "Your password must be between 8 and 32.",
  NEW_PASSWORD_LENGTH: "New password should be at least 8 characters long.",
  PHONE_LENGTH: "Phone number must be between 8 to 15 digits.",
  PHONE_VALID: "Please enter a valid phone number.",
  PASSWORD_CONFIRM_REQUIRED: "Please enter the confirm new password.",
  PASSWORD_CONFIRM_ERROR: "Confirm new password must be same as new password.",
  NEW_OLD_PASSWORD: "Old password and new password should not be same.",
  NAME: "Please enter full name.",
  HEIGTH: "Please select the height.",
  PHONE: "Please enter phone number.",
  NAME_LENGTH: "Full name length should be between 2 to 50 characters.",
  EDUCATION_LENGTH: "Education length should be between 2 to 50 characters.",
  EDUCATION: "Please enter education details.",
  VALID_EDUCATION: "Please enter the valid education details.",
  EMPLOYMENT_LENGTH: "Occupation length should be between 2 to 50 characters.",
  EMPLOYMENT: "Please enter the occupation.",
  VALID_EMPLOYMENT: "Please enter the valid occupation.",
  BIO: "Please enter bio.",
  BIO_LENGTH: "Bio length should be between 3 to 1000 characters.",
  DOB: "Please select DOB.",
  FITNESS: "Please select fitness.",
  ATTRACTED: "Please select a gender you're attracted to.",
  INTENSIONS: "Please select intentions.",
  Religion: "Please select Religion.",
  MARITAL: "Please select marriage preference.",
  PARENTAL: "Please select child preference.",
  MUSIC: "Please select music.",
  MOVIE: "Please select movie.",
  QUESTION: "Please enter the question.",
  optionA: "Please enter the option A.",
  optionA_length: "Option 'A' length should be greater than 3 characters.",
  optionB_length: "Option 'B' length should be greater than 3 characters.",
  optionC_length: "Option 'C' length should be greater than 3 characters.",
  optionD_length: "Option 'D' length should be greater than 3 characters.",
  optionB: "Please enter the option B.",
  optionC: "Please enter the option C.",
  optionD: "Please enter the option D.",
  QUESTION_LENGTH: "Question length should be greater than 3 characters.",
  correctOption: "Please select any one option.",
  gender: "Please select gender identity.",
  drinking: "Please select drinking.",
  smoking: "Please select smoking.",
  gambling: "Please select gambling.",
  VALID_GENDER: "Please enter valid gender.",
  GENDER_LENGTH: "Gender length should be at least 3 characters.",
  GENDER: "Please enter gender.",
  VALID_Religion: "Please enter valid religion.",
  Religion_LENGTH: "Religion length should be at least 3 characters.",
  Religion: "Please enter religion.",
  CITY: "Please enter city of residence.",
  CITY_LENGTH: "City of residence length should be at least 2 characters.",
};
// NAVBAR TABEL DATA
export const items = [
  {
    users: "Total users registered today",
    total: "todayRegisteredUser",
    img: <img src={images.Calender} alt={""} />,
    husers: "Total users registered today",
  },
  {
    users: "Total users registered this week",
    total: "weeklyRegisteredUser",
    img: <img src={images.Calender2} alt={""} />,
    husers: "Total users registered this week",
  },
  {
    users: "Total users registered this month",
    total: "monthlyRegisteredUser",
    img: <img src={images.Calender3} alt={""} />,
    husers: "Total users registered this month",
  },
  {
    users: "Total users registered in this quarter",
    total: "quaterlyRegisteredUser",
    img: <img src={images.Calender4} alt={""} />,
    husers: "Total users registered in this quarter",
  },
  {
    users: "Total users registered in last 6 months",
    total: "lastSixMonthRegisteredUser",
    img: <img src={images.Calender6} alt={""} />,
    husers: "Total users registered in this quarter",
  },
  {
    users: "Total users registered in last 1 year",
    total: "lastYearRegisteredUser",
    img: <img src={images.Calender5} alt={""} />,
    husers: "Total users registered in this quarter",
  },
];

export const Userheight = ["3", "4", "5", "6", "7", "8", "9"];
export const UserheightInch = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
];

export const IAPConstants = {
  "com.sparkdating.basic":"Basic Package ($6.99)",
  "com.sparkdating.super":"Super Package ($13.99)",
  "com.sparkdating.premium":"Premium Package ($27.99)"
}