import * as Yup from "yup";
import { validation } from "../themes/appconstant";

const errors = {
  //login
  login: Yup.object({
    password: Yup.string()
      .matches(/^(\S+$)/, validation.PASSWORD_REQUIRED)

      .required(validation.PASSWORD_REQUIRED),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
        validation.EMAIL_ERROR
      )
      .required(validation.EMAIL_REQUIRED),
  }),
  // forget password
  forgetPassword: Yup.object({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
        validation.EMAIL_ERROR
      )
      .email(validation.EMAIL_ERROR)
      .required(validation.EMAIL_REQUIRED),
  }),
  //resetpassword
  resetPassword: Yup.object({
    password: Yup.string()
      .max(32, validation.NEW_PASSWORD_LENGTH)
      .min(8, validation.NEW_PASSWORD_LENGTH)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^_+=.?~£])[A-Za-z\d@$!%*?&#^_+=.?~£]{8,32}$/,
        validation.PASSWORD_ERROR
      )
      .required(validation.PASSWORD_NEW_REQUIRED),

    confirmpassword: Yup.string()
      .required(validation.PASSWORD_CONFIRM_REQUIRED)
      .oneOf([Yup.ref("password")], validation.PASSWORD_CONFIRM_ERROR),
  }),
  //changepassword
  changePassword: Yup.object({
    oldPassword: Yup.string().required(validation.OLD_PASS_REQ),
    newPassword: Yup.string()

      .min(8, validation.NEW_PASSWORD_LENGTH)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^_+=.?~£])[A-Za-z\d@$!%*?&#^_+=.?~£]{8,32}$/,
        validation.New_PASSWORD_ERROR
      )
      .required(validation.NEW_PASS_REQ),
    confirmpassword: Yup.string()
      .required(validation.PASSWORD_CONFIRM_REQUIRED)
      .oneOf([Yup.ref("newPassword")], validation.PASSWORD_CONFIRM_ERROR),
  }),

  EditUser: Yup.object({
    name: Yup.string()
      .max(50, validation.NAME_LENGTH)
      .min(2, validation.NAME_LENGTH)
      .required(validation.NAME)
      .nullable(),

    gender: Yup.string().required(validation.gender).nullable(),
    attractedTo: Yup.array().min(1,validation.ATTRACTED).required(validation.ATTRACTED).nullable(),

  }),

  EditUserOtherGender: Yup.object({
    name: Yup.string()
      .max(50, validation.NAME_LENGTH)
      .min(2, validation.NAME_LENGTH)
      .required(validation.NAME)
      .nullable(),

    other: Yup.string()
      .required(validation.GENDER)
      .matches(/[A-Za-z]/, validation.VALID_GENDER)
      .min(3, validation.GENDER_LENGTH)
      .nullable(),
    gender: Yup.string().required(validation.gender).nullable(),

    attractedTo: Yup.array().min(1,validation.ATTRACTED).required(validation.ATTRACTED).nullable(),
  }),

  EditUserOtherReligion: Yup.object({
    name: Yup.string()
      .max(50, validation.NAME_LENGTH)
      .min(2, validation.NAME_LENGTH)
      .required(validation.NAME)
      .nullable(),

    otherReligion: Yup.string()
      .required("Please enter the religion")
      .matches(/[A-Za-z]/, "Please enter valid religion")
      .min(3, "Religion length should be at least 3 characters.")
      .nullable(),

      attractedTo: Yup.array().min(1,validation.ATTRACTED).required(validation.ATTRACTED).nullable(),
  }),
  EditUserOtherBoth: Yup.object({
    name: Yup.string()
      .max(50, validation.NAME_LENGTH)
      .min(2, validation.NAME_LENGTH)
      .required(validation.NAME)
      .nullable(),

    otherReligion: Yup.string()
      .required("Please enter the religion")
      .matches(/[A-Za-z]/, "Please enter valid religion")
      .min(3, "Religion length should be at least 3 characters.")
      .nullable(),
    other: Yup.string()
      .required(validation.GENDER)
      .matches(/[A-Za-z]/, validation.VALID_GENDER)
      .min(3, validation.GENDER_LENGTH)
      .nullable(),
    gender: Yup.string().required(validation.gender).nullable(),

    attractedTo: Yup.array().min(1,validation.ATTRACTED).required(validation.ATTRACTED).nullable(),
  }),

  QuestionModel: Yup.object({
    question: Yup.string().required(validation.QUESTION),

    optionA: Yup.string().required(validation.optionA),

    optionB: Yup.string().required(validation.optionB),

    optionC: Yup.string().required(validation.optionC),

    optionD: Yup.string().required(validation.optionD),
    correctOption: Yup.string().required(validation.correctOption),
  }),
};

export const validationSchema = (value, genderValid, religionValid) => {
  if (genderValid && religionValid) {
    return errors[value + "OtherBoth"];
  }
  if (genderValid) {
    return errors[value + "OtherGender"];
  }
  if (religionValid) {
    return errors[value + "OtherReligion"];
  }
  return errors[value];
};
