import { ImCross } from "react-icons/im";
import { toaster } from "../../utils/toaster";

export const InputFields = (props) => {
  return (
    <div className={`${props.OuterClass}`}>
      <label>{props.name}</label>
      <p className={`paragraph ${props.className}`}>{props.value ?? "N/A"}</p>
    </div>
  );
};

export const OnAddMoviesMusic = (DataLength, data, State, text, textState) => {
  if (DataLength.length < 5) {
    if (data.length > 0) {
      State((item) => [...item, { value: data, id: new Date().toString() }]);
    }
  } else {
    toaster(text);
  }
  textState("");
};

const validImage = (value) => {
  let imageExtension = ["jpg", "jpeg", "png", "svg"];
  let extension;
  for (let i of imageExtension) {
    if (value?.endsWith(i)) {
      extension = true;
    }
  }
  return extension || false;
};

export const OnAddMedia = (e, type, State, MediaLength) => {
  try {
    if (MediaLength < 6) {
      let obj = e.target.files[0];
      let IsValidMedia = validImage(obj?.name);
      let MediaBlob = URL.createObjectURL(obj);
      var reader = new FileReader(obj);
      reader.addEventListener("load", function () {
        let VideoDuration = new Audio(reader.result);
        VideoDuration.onloadedmetadata = function () {
          if (
            obj.type.startsWith("video") &&
            type === "video" &&
            VideoDuration.duration < 10.999
          ) {
            State((item) => {
              return [
                ...item,
                { blob: MediaBlob, Url: obj, Id: Math.random().toString() },
              ];
            });
          } else if (
            obj.type.startsWith("video") &&
            type === "video" &&
            VideoDuration.duration > 10.999
          ) {
            toaster("You can only add video up to 10 sec.");
          } else {
          }
        };
      },false);
      reader.readAsDataURL(obj);
      if (IsValidMedia && type === "image" && obj?.size < 21000000) {
        State((item) => {
          return [
            ...item,
            { blob: MediaBlob, Url: obj, Id: Math.random().toString() },
          ];
        });
      } else if (type === "image" && obj?.size > 21000000) {
        toaster("You can only add a image upto 20 MB.");
      } else if (IsValidMedia === false && type === "image") {
        toaster("You can only add an image(jpg,jpeg,png,svg).");
      }
    } else {
      toaster("You can only add up to 6 media.");
    }
  } catch (error) {
    toaster("Something went wronge.");
  }
};

export const IntresteName = {
  events: "Events",
  home: "Home",
  sports: "Sports",
  art: "Arts",
  digit: "Digital Media Genre’s",
  reading: "Reading Genre’s",
  music: "Music Genre’s",
  food: "Food & Drink",
  travel: "Travel",
  pets: "Pets",
  values: "Values",
  topics: "Topics",
};
export const IntrestIcon = {
  //Events
  Bars: require("../../images/bar 1.png").default,
  Café: require("../../images/cafe 1.png").default,
  Concerts: require("../../images/concert 1.png").default,
  "Sporting Events": require("../../images/stadium 1.png").default,
  Museums: require("../../images/museum 1.png").default,
  Nightclubs: require("../../images/nightlife 1.png").default,
  Theatre: require("../../images/theatre 1.png").default,
  Shopping: require("../../images/online-shopping 1.png").default,
  Festivals: require("../../images/tent 1.png").default,
  Arcade: require("../../images/arcade-machine 1.png").default,
  "Drive-Ins": require("../../images/drive-in 1.png").default,
  Restaurants: require("../../images/restaurant 1.png").default,

  //home
  "Board Games": require("../../images/board-game 1.png").default,
  Cooking: require("../../images/Cooking Pot.png").default,
  Baking: require("../../images/baking 1.png").default,
  Cleaning: require("../../images/house-cleaning 1.png").default,
  Gardening: require("../../images/gardening 1.png").default,
  "Video Games": require("../../images/game-console 1.png").default,
  Movies: require("../../images/movie 1.png").default,
  Music: require("../../images/music 1.png").default,
  TV: require("../../images/tv-monitor 1.png").default,
  Reading: require("../../images/book 1.png").default,
  //sport
  Soccer: require("../../images/soccer-player (1) 1.png").default,
  Basketball: require("../../images/basketball 1.png").default,
  Football: require("../../images/football 1.png").default,
  Baseball: require("../../images/baseball 1.png").default,
  Hockey: require("../../images/hockey 1.png").default,
  Tennis: require("../../images/tennis 1.png").default,
  Golf: require("../../images/golf 1.png").default,
  Badminton: require("../../images/badminton 1.png").default,
  Cricket: require("../../images/cricket 1.png").default,
  Lacrosse: require("../../images/lacrosse 1.png").default,
  Curling: require("../../images/curling 1.png").default,
  Rugby: require("../../images/rugby-ball 1.png").default,
  Weightlifting: require("../../images/weightlift 1.png").default,
  Sprinting: require("../../images/sprint 1.png").default,
  Cycling: require("../../images/cycling 1.png").default,
  Surfing: require("../../images/surfboard 1.png").default,
  Skiing: require("../../images/skiing 1.png").default,
  Running: require("../../images/runner 1.png").default,
  "Working Out": require("../../images/treadmill 1.png").default,
  Bowling: require("../../images/bowling 1.png").default,
  Swimming: require("../../images/swimming 1.png").default,
  Volleyball: require("../../images/volleyball 1.png").default,
  Gymnastics: require("../../images/gymnastic-rings 1.png").default,
  Handball: require("../../images/bar 1.png").default,
  "Water Polo": require("../../images/water-polo 1.png").default,
  "Martial Arts": require("../../images/martial-arts 1.png").default,
  Boxing: require("../../images/boxing-gloves 1.png").default,
  MMA: require("../../images/mma 1.png").default,
  Rowing: require("../../images/rowing 1.png").default,
  "Rock Climbing": require("../../images/climbing 1.png").default,
  //Art
  Singing: require("../../images/sing 1.png").default,
  Dancing: require("../../images/dancer 1.png").default,
  Drawing: require("../../images/drawing (1) 1.png").default,
  Painting: require("../../images/paint-palette 1.png").default,
  Photography: require("../../images/photography 1.png").default,
  "Graphic Design": require("../../images/graphic-design 1.png").default,
  Scrapbooking: require("../../images/scrapbook 1.png").default,
  Filmmaking: require("../../images/videography 1.png").default,
  "Make-Up": require("../../images/makeup 1.png").default,
  Snowboarding: require("../../images/snowboarding 1.png").default,
  // Digital
  Action: require("../../images/videography 1.png").default,
  Comedy: require("../../images/cinema 1.png").default,
  Horror: require("../../images/horror-movie 1.png").default,
  Documentaries: require("../../images/documentary 1.png").default,
  Bollywood: require("../../images/bollywood 1.png").default,
  "Cooking Shows": require("../../images/cooking-show 1.png").default,
  Crime: require("../../images/murder 1.png").default,
  Drama: require("../../images/drama 1.png").default,
  Fantasy: require("../../images/fairytale 1.png").default,
  Reality: require("../../images/platform 1.png").default,
  Mystery: require("../../images/mystery 1.png").default,
  "Sci-Fi": require("../../images/sci-fi 1.png").default,
  Thriller: require("../../images/film (1) 1.png").default,
  Superhero: require("../../images/hero 1.png").default,
  "Game Shows": require("../../images/competition 1.png").default,
  //reading
  Biographies: require("../../images/biography 1.png").default,
  Classics: require("../../images/spartan 1.png").default,
  Comics: require("../../images/comic-book 1.png").default,
  History: require("../../images/history 1.png").default,
  Manga: require("../../images/manga 1.png").default,
  Philosophy: require("../../images/book 1.png").default,
  Poetry: require("../../images/poetry 1.png").default,
  Psychology: require("../../images/psychology 1.png").default,
  Romance: require("../../images/romance (1) 1.png").default,
  Educational: require("../../images/education 1.png").default,
  //music
  Blues: require("../../images/trumpet (1) 1.png").default,
  Classical: require("../../images/harp 1.png").default,
  Country: require("../../images/trumpet 1.png").default,
  "Hip Hop": require("../../images/hip-hop 1.png").default,
  "K-Pop": require("../../images/kpop 1.png").default,
  Pop: require("../../images/pop 1.png").default,
  Punk: require("../../images/punk 1.png").default,
  "R&B": require("../../images/podcast 1.png").default,
  Rap: require("../../images/rap 1.png").default,
  Rock: require("../../images/bass-guitar 1.png").default,
  Jazz: require("../../images/saxophone 1.png").default,
  Reggae: require("../../images/videography 1.png").default,
  Latin: require("../../images/maracas 1.png").default,
  Metal: require("../../images/rock-and-roll 1.png").default,
  //FOOD
  "Bubble Tea": require("../../images/bubble-tea 1.png").default,
  Coffe: require("../../images/coffee-cup 1.png").default,
  Pizza: require("../../images/pizza 1.png").default,
  Beer: require("../../images/Group.png").default,
  Gin: require("../../images/Drink.png").default,
  Wine: require("../../images/wine.png").default,
  Cocktails: require("../../images/Cocktail.png").default,
  Sushi: require("../../images/Sushi.png").default,
  Sweets: require("../../images/Sweets.png").default,
  Savoury: require("../../images/Pie.png").default,
  Vegan: require("../../images/veg food.png").default,
  Vegetarian: require("../../images/vegetarian-100 1.png").default,
  Tea: require("../../images/tea.png").default,
  Pop: require("../../images/icons8-street-food-96 1.png").default,
  Burgers: require("../../images/icons8-burger-100 1.png").default,
  // TRAVEL
  Backpacking: require("../../images/backpack.png").default,
  Beach: require("../../images/beach.png").default,
  Camping: require("../../images/camping.png").default,
  Fishing: require("../../images/fishing.png").default,
  Hiking: require("../../images/icons8-hiking-100 1.png").default,
  "Road Trips": require("../../images/road.png").default,
  "Winter Vacations": require("../../images/sea.png").default,
  //pets
  Birds: require("../../images/bird-96 1.png").default,
  Cats: require("../../images/cat.png").default,
  Dogs: require("../../images/Dog.png").default,
  Fish: require("../../images/fish.png").default,
  Lizards: require("../../images/icons8-lizard-96 1.png").default,
  Rabbits: require("../../images/icons8-year-of-rabbit-96 1.png").default,
  Hamsters: require("../../images/icons8-cute-hamster-96 1.png").default,
  //values
  Ambition: require("../../images/ambition.png").default,
  Active: require("../../images/icons8-active-58 1.png").default,
  Family: require("../../images/family.png").default,
  Openness: require("../../images/filled outline.png").default,
  Romantic: require("../../images/flat.png").default,
  Confidence: require("../../images/icons8-confidence-64 1.png").default,
  Creative: require("../../images/rock-and-roll 1.png").default,
  Empathy: require("../../images/icons8-empathy-58 1.png").default,
  Intelligence: require("../../images/icons8-intelligence-64 1.png").default,
  Positivity: require("../../images/icons8-positive-thinking-64 1.png").default,
  "Self-Awareness": require("../../images/icons8-self-esteem-64 1.png").default,
  Adventure: require("../../images/two tone.png").default,
  Funny: require("../../images/icons8-crazy-64 1.png").default,
  Bravery: require("../../images/icons8-brave-64 1.png").default,
  Honesty: require("../../images/4711558441639553926 1.png").default,
  Communication: require("../../images/justice.png").default,
  Astrology: require("../../images/4895924381644768498 1.png").default,
  //TOPICS
  Politics: require("../../images/20342722011637655249 1.png").default,
  "Right Wing": require("../../images/icons8-right-64 1 (1).png").default,
  "Left Wing": require("../../images/icons8-right-64 1.png").default,
  Feminism: require("../../images/icons8-feminism-58 1.png").default,
  "LGBTQIA2S+": require("../../images/11097386091638337136 1.png").default,
  Psychology: require("../../images/icons8-psychology-64 1.png").default,
  Sociology: require("../../images/icons8-sociology-64 1.png").default,
  Economics: require("../../images/icons8-economics-94 1.png").default,
  Archaeology: require("../../images/icons8-archaeology-64 1.png").default,
  History: require("../../images/rock-and-roll 1.png").default,
  Mythology: require("../../images/rock-and-roll 1.png").default,
  Finance: require("../../images/rock-and-roll 1.png").default,
  Biology: require("../../images/icons8-biology-64 1.png").default,
  Physics: require("../../images/20170556571636191859 1.png").default,
  Chemistry: require("../../images/icons8-chemistry-64 1.png").default,
  Math: require("../../images/Math.png").default,
  Marketing: require("../../images/Data.png").default,
  Celebrity: require("../../images/10681683331642169449 1.png").default,
  Environmentalism: require("../../images/3417832621640177485 1.png").default,
};

export const InteresteFn = (data) => {
  return Object?.entries(data).map(([key, value]) => {
    return (
      <>
        {value && value?.length > 0 && (
          <p className="IntrestLabels">{IntresteName[key]}</p>
        )}
        {value.length > 0 &&
          value.map((item) => {
            return (
              <button type="button" className="item_interests">
                <div className="InterestsDiv">
                  <div className="InterestsIconStyle">
                    <img
                      src={IntrestIcon[item]}
                      width="100%"
                      height="100%"
                      alt=""
                    />
                  </div>
                  {item}
                </div>
              </button>
            );
          })}
      </>
    );
  });
};

export const ValuesTopicFn = (data) => {
  return data?.map((item) => {
    return (
      <>
        <button type="button" className="item_interests">
          <div className="InterestsDiv">
            <div className="InterestsIconStyle">
              <img src={IntrestIcon[item]} width="100%" height="100%" alt="" />
            </div>
            {item}
          </div>
        </button>
      </>
    );
  });
};

const onMusicMoviesCancel = (id, state, setState) => {
  let newMusic = [...state];
  let index = newMusic.findIndex((item) => item.id === id);
  newMusic.splice(index, 1);
  setState(newMusic);
};

export const ShowMusicVideo = (value, title, state, setState) => {
  return (
    <div className="textTagDiv">
      {value?.map((item, index) => {
        return (
          <div
            className="textTag"
            style={{
              background: colorObj[index].background,
              border: `1px solid ${colorObj[index].border}`,
              color: `${colorObj[index].text}`,
            }}
          >
            {item.value}
            <ImCross
              size={15}
              title={title}
              onClick={() => {
                onMusicMoviesCancel(item?.id, state, setState);
              }}
              cursor={"pointer"}
              style={{
                boxSizing: "border-box",
                padding: "5px 0px 0px 6px ",
                color: "rgb(115, 115, 115)",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export const colorObj = [
  {
    background: "rgb(255, 0, 255,0.09)",
    border: "rgb(270, 0, 270,0.7)",
    text: "rgb(270, 0, 270)",
  },
  {
    background: "#fcffe6",
    border: "#eaff8f",
    text: "#7cb305",
  },
  {
    background: "rgb(255, 165, 0,0.09)",
    border: "rgb(270, 185, 0,0.7)",
    text: "rgb(270, 185, 0)",
  },
  {
    background: "rgb(0, 128, 0,0.09)",
    border: "rgb(15, 143, 0,.7)",
    text: "rgb(15, 143, 0)",
  },
  {
    background: "rgb(0, 0, 255,0.09)",
    border: "rgb(0, 0, 270,0.7)",
    text: "rgb(0, 0, 270)",
  },
];
