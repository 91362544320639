import React from "react";

//routers
import { Routes, Route, Navigate } from "react-router-dom";

//react-redux"
import { useSelector } from "react-redux";

//components
import Changepassword from "../components/changepassword";
import Dashboard from "../components/dashboard";
import EditUser from "../components/usermanagement/edituser";
import ForgotPassword from "../components/forgotpassword";
import Login from "../components/login";
import UserManagement from "../components/usermanagement";
import UserView from "../components/usermanagement/viewuser";
import FandomQuizManagement from "../components/fandomQuizManagement";
import InAppPurchaseManagement from "../components/in-apppurchase";
import FandomQuizManagementAddQuiz from "../components/fandomQuizManagement/addQuiz";
import FandomQuizManagementEditQuiz from "../components/fandomQuizManagement/editQuiz";
import ResetPassword from "../components/resetPassword";

const RoutesNew = () => {
    const { token } = useSelector((state) => state.AuthReducer)
    return (
        <>
            {token ?
                <Routes>
                    <Route path="" element={<Navigate to={'/dashboard'} />} />
                    <Route path="/dashboard" exact element={<Dashboard />} />
                    <Route path="/usermanagement" element={<UserManagement />} />
                    <Route path="/userview" element={<UserView />} />
                    <Route path="/useredit" element={<EditUser />} />
                    <Route path="/fandom" element={<FandomQuizManagement />} />
                    <Route path="/fandomquiz" element={<FandomQuizManagementAddQuiz />} />
                    <Route path="/fandomeditquiz" element={<FandomQuizManagementEditQuiz />} />
                    <Route path="/purchase" element={<InAppPurchaseManagement />} />
                    <Route path="/changepassword" element={<Changepassword />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                :
                <Routes>
                    <Route path="/" element={<Navigate to={'/login'} />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/resetpassword" element={<ResetPassword />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            }
        </>
    )
}
export default RoutesNew;


