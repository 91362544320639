import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Modal } from "antd";
//formik
import { Formik } from "formik";
import { dropdownListAction } from "../../Redux/Actions/userManagmennt";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Textfiled } from "../../common";
import { validationSchema } from "../../utils/ValidationSchema";

export const QuestionModel = ({ cb, isClick, title, data }) => {
  const [QuestionData, setQuestionData] = useState({});
  const dispatch = useDispatch();
  const { dropdown } = useSelector((state) => state.userManagmenntReducer);
  useEffect(() => {
    dispatch(dropdownListAction());
    let newdata = {};
    if (title === "Edit Question") {
      newdata = {
        question: data?.question,
        optionA: data?.options[0],
        optionB: data?.options[1],
        optionC: data?.options[2],
        optionD: data?.options[3],
        correctOption: data?.correctOption,
      };
    } else {
      newdata = {
        fandomId: "",
        question: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        correctOption: "",
      };
    }
    setQuestionData(newdata);
  }, [data]);
  const OnSubmit = (value) => {
    if (title === "Edit Question") {
      let obj = {
        questionId: data?._id,
        question: value?.question,
        options: [value.optionA, value.optionB, value.optionC, value.optionD],
        correctOption: value.correctOption,
      };
      cb({ data: obj, click: false });
    } else if (title === "Add Question") {
      let obj = {
        fandomId: data.ID,
        question: value.question,
        options: [value.optionA, value.optionB, value.optionC, value.optionD],
        correctOption: value.correctOption,
      };
      cb({ data: obj, click: false });
    }
  };
  const onCancelClick = (data) => {
    cb({ data: {}, click: false });
  };
  return (
    <Formik
      initialValues={QuestionData}
      validationSchema={validationSchema("QuestionModel")}
      onSubmit={OnSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue,
      }) => (
        <Modal
          title={title}
          visible={isClick}
          okText="Add"
          onCancel={() => cb({ data: {}, click: false })}
          footer={false}
        >
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <Textfiled
                error={Boolean(touched.question && errors.question)}
                helperText={touched.question && errors.question}
                id="filled-basic"
                label="Question"
                variant="standard"
                className="filed-control"
                value={values.question?.trimStart()}
                name="question"
                onChangeText={(e) => {
                  const newData = e.target.value.trimStart();
                  setFieldValue("question", newData);
                }}
              />
            </div>
            <p className="option_lite">Options</p>
            <div className="form-group">
              <Textfiled
                error={Boolean(touched.optionA && errors.optionA)}
                helperText={touched.optionA && errors.optionA}
                id="filled-basic"
                label="Option A"
                variant="standard"
                className="filed-control"
                value={values.optionA?.trimStart()}
                name="optionA"
                onChangeText={(e) => {
                  const newData = e.target.value.trimStart();
                  setFieldValue("optionA", newData);
                }}
              />
            </div>

            <div className="form-group">
              <Textfiled
                error={Boolean(touched.optionB && errors.optionB)}
                helperText={touched.optionB && errors.optionB}
                id="filled-basic"
                label="Option B"
                variant="standard"
                className="filed-control"
                value={values.optionB?.trimStart()}
                name="optionB"
                onChangeText={(e) => {
                  const newData = e.target.value.trimStart();
                  setFieldValue("optionB", newData);
                }}
              />
            </div>
            <div className="form-group">
              <Textfiled
                error={Boolean(touched.optionC && errors.optionC)}
                helperText={touched.optionC && errors.optionC}
                id="filled-basic"
                label="Option C"
                variant="standard"
                className="filed-control"
                value={values.optionC?.trimStart()}
                name="optionC"
                onChangeText={(e) => {
                  const newData = e.target.value.trimStart();
                  setFieldValue("optionC", newData);
                }}
              />
            </div>
            <div className="form-group">
              <Textfiled
                error={Boolean(touched.optionD && errors.optionD)}
                helperText={touched.optionD && errors.optionD}
                id="filled-basic"
                label="Option D"
                variant="standard"
                className="filed-control"
                value={values.optionD?.trimStart()}
                name="optionD"
                onChangeText={(e) => {
                  const newData = e.target.value.trimStart();
                  setFieldValue("optionD", newData);
                }}
              />
            </div>
            <div className="correctoption">
              <div className="form-group">
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  className="TextField"
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Correct option
                  </InputLabel>
                  <Select
                    error={Boolean(
                      touched.correctOption && errors.correctOption
                    )}
                    helperText={touched.correctOption && errors.correctOption}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={values.correctOption ?? "A"}
                    name="correctOption"
                    onChange={handleChange}
                  >
                    {dropdown?.options?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  {touched.correctOption && (
                    <p className="SelectInputValidation">
                      {errors.correctOption}
                    </p>
                  )}
                </FormControl>
              </div>
            </div>
            <>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  type="cancel"
                  onClick={onCancelClick}
                  className="button_edit"
                >
                  Cancel
                </button>
                <button type="submit" className="button_edit">
                  Ok
                </button>
              </div>
            </>
          </form>
        </Modal>
      )}
    </Formik>
  );
};
export default QuestionModel;
