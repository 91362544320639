import { put, call } from "@redux-saga/core/effects";

// utils
import { toaster } from "../../utils/toaster";

//constant
import { fandomQuiz, Auth } from "../../themes/ReduxConstant";

// api calling methods
import {
  fandomQuizLoad,
  fandomQuizDeleteApi,
  fandomQuizQuestionDeleteApi,
  fandomQuizQuestionApi,
  fandomQuizQuestionEditApi,
  fandomQuizQuestionAddApi,
} from "../Api";

//user list
export function* fandomQuizLoadSaga(action) {
  try {
    let data = yield call(fandomQuizLoad, action.payload);
    if (data.status === 0) {
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_TOPIC_SUCCESS,
        payload: data.result,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_TOPIC_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
export function* fandomQuizDeleteSaga(action) {
  try {
    let data = yield call(fandomQuizDeleteApi, action.payload.userId);
    if (data.status === 0) {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_TOPIC_DELETE_SUCCESS,
        payload: data.result,
      });

      yield put({
        type: fandomQuiz.FANDOM_QUIZ_TOPIC_LOAD,
        payload: action.payload.data,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_TOPIC_DELETE_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
export function* fandomQuizQuestionDeleteSaga(action) {
  try {
    let data = yield call(fandomQuizQuestionDeleteApi, action.payload.userId);
    if (data.status === 0) {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_DELETE_SUCCESS,
        payload: data.result,
      });

      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_LOAD,
        payload: action.payload.data,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_DELETE_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
export function* fandomQuizQuestionListSaga(action) {
  try {
    // let data
    let data = yield call(fandomQuizQuestionApi, action.payload);
    if (data.status === 0) {
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_SUCCESS,
        payload: data.result,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
export function* fandomQuizQuestionEditSaga(action) {
  try {
    let data = yield call(
      fandomQuizQuestionEditApi,
      action.payload.Questiondata.data
    );
    if (data.status === 0) {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_EDIT_SUCCESS,
        payload: data.result,
      });

      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_LOAD,
        payload: action.payload.data,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_EDIT_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
export function* fandomQuizQuestionAddSaga(action) {
  try {
    let data = yield call(
      fandomQuizQuestionAddApi,
      action.payload.Questiondata
    );
    if (data.status === 0) {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_EDIT_SUCCESS,
        payload: data.result,
      });

      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_LOAD,
        payload: action.payload.data,
      });
    } else if (data.status === 2) {
      toaster(data?.result?.message);
      yield put({
        type: Auth.RESET,
      });
    } else {
      toaster(data?.result?.message);
      yield put({
        type: fandomQuiz.FANDOM_QUIZ_QUESTION_EDIT_FAIL,
        payload: data.result,
      });
    }
  } catch (error) {}
}
