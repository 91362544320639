import React, { useState, useEffect } from "react";

//UI
import { Layout, Breadcrumb } from "antd";

//formik
import { Formik } from "formik";
//redux
import { useDispatch } from "react-redux";
import { changePasswordAction } from "../../Redux/Actions/Auth";

//router
import { Link } from "react-router-dom";

//layouts
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";

//common
import { Textfiled } from "../../common";

//themes
import { appconstant } from "../../themes/appconstant";
import { validation } from "../../themes/appconstant";
import { toaster } from "../../utils/toaster";

// utils
import { validationSchema } from "../../utils/ValidationSchema";

const { Content } = Layout;

const Changepassword = () => {
  const [isVisible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = appconstant.Titles.changepassword;
    window.scrollTo(0, 0);
  }, []);
  const onSubmit = ({ newPassword, oldPassword }) => {
    newPassword !== oldPassword
      ? dispatch(changePasswordAction({ newPassword, oldPassword }))
      : toaster(validation.NEW_OLD_PASSWORD);
  };
  return (
    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">{appconstant.home}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{appconstant.changepassword}</Breadcrumb.Item>
        </Breadcrumb>
        <Content className="new_layout_content">
          <div className="site-layout-background">
            <div className="content-e">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                <h6 className="text-white text-capitalize ps-3">
                  {appconstant.changepassword}
                </h6>
              </div>
              <div className="wrapper_line">
                <Formik
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmpassword: "",
                  }}
                  validationSchema={validationSchema("changePassword")}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <Textfiled
                          error={Boolean(
                            touched.oldPassword && errors.oldPassword
                          )}
                          helperText={touched.oldPassword && errors.oldPassword}
                          label="Old Password"
                          variant="standard"
                          className="filed-control"
                          type="password"
                          name="oldPassword"
                          value={values.oldPassword.trim()}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("oldPassword",newData);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <Textfiled
                          error={Boolean(
                            touched.newPassword && errors.newPassword
                          )}
                          helperText={touched.newPassword && errors.newPassword}
                          type="password"
                          label="New Password"
                          variant="standard"
                          className="filed-control"
                          name="newPassword"
                          value={values.newPassword.trim()}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("newPassword",newData);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <Textfiled
                          error={Boolean(
                            touched.confirmpassword && errors.confirmpassword
                          )}
                          helperText={
                            touched.confirmpassword && errors.confirmpassword
                          }
                          type="password"
                          label="Confirm New Password"
                          variant="standard"
                          className="filed-control"
                          name="confirmpassword"
                          value={values.confirmpassword.trim()}
                          onChangeText={(e) => {
                            const newData = e.target.value.trimStart();
                            setFieldValue("confirmpassword",newData);
                          }}
                        />
                      </div>
                      <button type="submit" className="button-list">
                        {appconstant.buttonupate}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Changepassword;
