import React from "react";

//UI
import { Layout } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";

const { Header } = Layout;

const HeaderAdmin  = (props) =>{
    return(
       <div>
            <Header className="site-layout-sub-header-background Sub_header"  >
                <div className="notification button">
           
                </div>
                <div className="iocns">
             <FontAwesomeIcon icon={faBars} className="lite-text" onClick={props.handlewClick} />
            </div>
            
            
            </Header>
        </div>
    )
}
export default HeaderAdmin;