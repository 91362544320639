import { combineReducers } from "redux";
import { Auth } from "../../themes/ReduxConstant";
import { AuthReducer } from "./AuthReducer";
import { dashboardReducer } from "./dashboardReducr";
import { userManagmenntReducer } from "./userManagment.js";
import { userPurchaseReducer } from "./purchaseListReduce";
import { fandomMAnagementReducer } from "./fandomManagement";
const appReducer = combineReducers({
  AuthReducer,
  dashboardReducer,
  userManagmenntReducer,
  userPurchaseReducer,
  fandomMAnagementReducer,
});
const rootReducer = (state, action) => {
  return appReducer(action.type === Auth.RESET ? undefined : state, action); // to reset the redux
};
export default rootReducer;
