import React, { useEffect, useState } from "react"

//routers
import { useLocation, useNavigate } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAction, resetPasswordLinkVerify } from "../../Redux/Actions/Auth";

//formik
import { Formik } from 'formik';
//common
import {Textfiled} from "../../common";

//themes
import images from '../../themes/appImage'
import { appconstant } from '../../themes/appconstant'
import { validationSchema } from "../../utils/ValidationSchema";

const ResetPassword = () => {
    const { search } = useLocation()
    const [token, setToken] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { isVerfy } = useSelector(state => state.AuthReducer)
    useEffect(() => {
        document.title = appconstant.Titles.ResetPassword;
        if (!token)
            setToken(search.slice(8))
        token && dispatch(resetPasswordLinkVerify({ token }));
    }, [token])
    const onSubmit = ({ password }) => {
        dispatch(resetPasswordAction({ password, token, navigate }))
    }

    return (
        <div className="image-bg">
            <div className="page-header">
                <div className="line_true">
                    <div className="content-e ">
                        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                            <h6 className="text-white text-capitalize ps-3 text-center">Reset Password</h6>
                        </div>
                        <div className="text-center cost">
                            <img src={images.logo} alt=""/>

                        </div>
                        {isVerfy ?
                            <div className="wrapper_line">

                                <Formik initialValues={{
                                    password: "",
                                    confirmpassword: "",
                                }}
                                    validationSchema={validationSchema("resetPassword")}
                                    onSubmit={onSubmit}
                                >
                                    {({
                                        values,
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        touched,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">

                                                <Textfiled
                                                    error={Boolean(touched.password && errors.password)}
                                                    helperText={touched.password && errors.password}
                                                    label='New Password'
                                                    variant='standard'
                                                    className='filed-control'
                                                    type='password'
                                                    name="password"
                                                    value={values.password.trim()}
                                                    onChangeText={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <Textfiled
                                                    error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                                                    helperText={touched.confirmpassword && errors.confirmpassword}
                                                    type='password'
                                                    label='Confirm Password'
                                                    variant='standard'
                                                    className='filed-control'
                                                    name="confirmpassword"
                                                    value={values.confirmpassword.trim()}
                                                    onChangeText={handleChange}
                                                />
                                            </div>
                                            <div className='text-center_login'>
                                                <button type="submit" className="button-list">Reset</button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                            :
                            <div className="forValid_div">
                                <span className="forValid">Link Expired</span>
                                <p className="forValid_p">The link you trying to access is no longer available.</p>
                                <div className='text-center_login'>
                                    <button type="submit" className="button-list BackToLogin" onClick={() => navigate('/login')}>Back to Login</button>
                                </div>
                                <p className="forValid_p copyRights">Copyright 2022 © SparkDating. All rights reserved. </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}
export default ResetPassword;