import { put, call } from "@redux-saga/core/effects";

// utils
import { toaster } from "../../utils/toaster";

//constant
import {  Auth, Userpurchase } from "../../themes/ReduxConstant"

// api calling methods
import { UserPurchaseList } from "../Api";

//user list
export function* userPurchaseListSaga(action) {

    try {
        let data = yield call(UserPurchaseList, action.payload);
        if (data.status === 0) {
            yield put({
                type: Userpurchase.USER_PURCHASE_SUCCESS,
                payload: data.result
            })
        }
        else if (data.status === 2) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.RESET
            })
        }
        else {
            toaster(data?.result?.message)
            yield put({
                type: Userpurchase.USER_PURCHASE_FAIL,
                payload: data.result
            })
        }
    } catch (error) {

    }
}