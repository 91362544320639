import React, { useState, useEffect } from "react";

//UI
import { Layout, Table, Breadcrumb } from "antd";

//routers
import { Link, useNavigate } from "react-router-dom";

//layouts
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";

//themes
import { appconstant } from "../../themes/appconstant";
import {
  RemovePaginationData,
  usersAction,
  usersBlockAction,
  usersDeleteAction,
} from "../../Redux/Actions/userManagmennt";
import { useDispatch, useSelector } from "react-redux";
// CONSTANT
import { TabelHead } from "../../utils/tabelHeader";
import { OnPagenationHandelChange, SearchBarFn } from "../../common";
const { Content } = Layout;

const instialData = {
  sortBy: "",
  order: "",
  search: "",
  offset: 0,
  limit: 10,
};
const UserManagement = () => {
  const [isVisible, setVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState(instialData);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  const dispatch = useDispatch();
  const dataSource = useSelector((state) => state.userManagmenntReducer);
  const navigate = useNavigate();
    // this function dispatch action when user click on the delete button or block button
  const cb = (isButtonClick, name, userData) => {
    if (isButtonClick && name === appconstant.Delete_Title) {
      dispatch(usersDeleteAction({ userId: userData._id, data: data }));
    }
    if (
      (isButtonClick && name === appconstant.block_Title) ||
      (isButtonClick && name === appconstant.unBlock_Button)
    ) {
      dispatch(usersBlockAction({ userId: userData._id, data: data }));
    }
  };
  useEffect(() => {
    document.title = appconstant.Titles.UserManagement;
    return () => {
      dispatch(RemovePaginationData());
    };
  }, []);
// this useEffect for dispatch the action when ever their are changes in the search input
  useEffect(() => {
    SearchBarFn(instialData,dispatch,usersAction,setData,dataSource.paginateData.currentPage,searchInput,data);
  }, [searchInput]);

  const onButtonClick = (data) => {
    data?.name === appconstant.view && navigate("/userview", { state: data });
    data?.name === appconstant.edit && navigate("/useredit", { state: data });
  };
  // the tabel handel change fn.
  const handleChange = (pagination, filters, sorter) => {
    OnPagenationHandelChange(pagination,sorter,data,setData,dispatch,usersAction);
  };
  return (
    <div>
      <Layout>
        <Sidebar />
        <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
        <Layout className="new_layout">
          <HeaderAdmin handlewClick={handlewClick} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">{appconstant.home}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{appconstant.UserManagement}</Breadcrumb.Item>
          </Breadcrumb>
          <Content className="new_layout_content">
            <div className="site-layout-background">
              <div className="content-e">
                <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 className="text-white text-capitalize ps-3">
                    {appconstant.UserManagement}
                  </h6>
                  <input
                    type="text"
                    className="search-bar"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value.trimStart());
                    }}
                  />
                </div>
                <Table
                  // className="sortIcon"
                  dataSource={[...dataSource?.userList]}
                  columns={TabelHead(
                    (isButtonClick, name, userData) =>
                      cb(isButtonClick, name, userData),
                    (data, name) => onButtonClick(data, name),
                    dataSource?.paginateData?.currentPage
                  )}
                  showSorterTooltip={false}
                  onChange={handleChange}
                  pagination={{
                    current: dataSource?.paginateData?.currentPage ?? 1,
                    total: dataSource?.paginateData?.totalCount,
                  }}
                  loading={dataSource?.isLoading}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default UserManagement;
