import { fandomQuiz, UserManagement } from "../../../themes/ReduxConstant";
export const fandomQuizload = (data) => {
  return {
    type: fandomQuiz.FANDOM_QUIZ_TOPIC_LOAD,
    payload: data,
  };
};
export const QuizTopicDeleteAction = (data) => {
  return {
    type: fandomQuiz.FANDOM_QUIZ_TOPIC_DELETE_LOAD,
    payload: data,
  };
};
export const fandomQuizQuestionload = (data) => {
  return {
    type: fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_LOAD,
    payload: data,
  };
};
export const fandomQuizQuestionAddload = (data) => {
  return {
    type: fandomQuiz.FANDOM_QUIZ_QUESTION_ADD_LOAD,
    payload: data,
  };
};
export const QuizQuestionDeleteAction = (data) => {
  return {
    type: fandomQuiz.FANDOM_QUIZ_QUESTION_DELETE_LOAD,
    payload: data,
  };
};
export const QuizQuestionEditAction = (data) => {
  return {
    type: fandomQuiz.FANDOM_QUIZ_QUESTION_EDIT_LOAD,
    payload: data,
  };
};
// export const RemovePaginationData = () => {
//   return {
//       type: UserManagement.ON_PAGE_LEAVE_LOAD,
//   }
// }
// export const QuizQuestionEditAction = (data) => {
//   return {
//     type: fandomQuiz.FANDOM_QUIZ_QUESTION_EDIT_LOAD,
//     payload: data,
//   };
// };
