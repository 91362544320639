import { APIMethod } from "./ApiMethods";
import { APIFileMethod } from "./FileMethods";

//login
export const adminLoginApi = (adminInfo) => {
  return APIMethod.POST("admin/login", JSON.stringify(adminInfo));
};

//forgotPassword
export const adminForgetPassApi = (adminInfo) => {
  return APIMethod.POST("admin/forgotPassword", JSON.stringify(adminInfo));
};

//logout
export const adminLogoutApi = () => {
  return APIMethod.GET("admin/logout");
};

//resetPassword
export const adminResetPasswordApi = (adminInfo) => {
  return APIMethod.POST("admin/resetpassword", JSON.stringify(adminInfo));
};

//changePassword
export const adminChangePasswordApi = (adminInfo) => {
  return APIMethod.POST("admin/changePassword", JSON.stringify(adminInfo));
};
//changePassword
export const admintokenVerfyApi = (adminInfo) => {
  return APIMethod.POST("admin/forgotLinkvalid", JSON.stringify(adminInfo));
};

//DASHBOARD
export const DashboardRegisterUser = (userInfo) => {
  return APIMethod.GET("admin/dashboard", JSON.stringify(userInfo));
};

//userManagment
export const UserManagementUserList = (userInfo) => {
  return APIMethod.POST("admin/userList", JSON.stringify(userInfo));
};

//userManagment_DELETE USER
export const UserDeleteApi = (userInfo) => {
  return APIMethod.DELETE("user/delete", JSON.stringify(userInfo));
};
//userManagment_BLOCK USER
export const UserBlockApi = (userInfo) => {
  return APIMethod.GET(`admin/blockUser/${userInfo}`);
};

// view user
export const UserViewApi = (userInfo) => {
  return APIMethod.GET(`admin/details/${userInfo}`);
};

//edit user
export const UserEditApi = (userInfo) => {
  return APIFileMethod.PUT(`admin/editUser`, userInfo);
};
//dropdown
export const DropDownApi = () => {
  return APIMethod.GET(`user/dropdowns`);
};
//intrests

export const InterestApi = () => {
  return APIMethod.GET(`user/intrests`);
};

//Purchase list
export const UserPurchaseList = (userInfo) => {
  return APIMethod.POST(`admin/purchase`, JSON.stringify(userInfo));
};
export const fandomQuizLoad = (userInfo) => {
  return APIMethod.POST(`admin/fandomList`, JSON.stringify(userInfo));
};
export const fandomQuizDeleteApi = (userInfo) => {
  return APIMethod.DELETE(`admin/deleteFandom/${userInfo}`);
};
export const fandomQuizQuestionDeleteApi = (userInfo) => {
  return APIMethod.DELETE(`premium/delete/${userInfo}`);
};
export const fandomQuizQuestionApi = (userInfo) => {
  return APIMethod.POST(`premium/questionList`, JSON.stringify(userInfo));
};
export const fandomQuizQuestionEditApi = (userInfo) => {
  return APIMethod.PUT(`premium/questionEdit`, JSON.stringify(userInfo));
};
export const fandomQuizQuestionAddApi = (userInfo) => {
  return APIMethod.POST(`admin/addQuiz`, JSON.stringify(userInfo));
};
