// constant
import { Dashboard } from "../../../themes/ReduxConstant"

const instialValue = {
    isLoading: false,
    data: []
}
export const dashboardReducer = (state = instialValue, action) => {
    switch (action.type) {
        case Dashboard.REGISTER_USERS_DATA_LOAD:
            return {
                ...state,
                isLoading: true
            }
        case Dashboard.REGISTER_USERS_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }
        case Dashboard.REGISTER_USERS_DATA_FAIL:
            return {
                ...state,
            }
        default: return state
    }
}
