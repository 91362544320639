
import { put, call } from "@redux-saga/core/effects";

// utils
import { toaster } from "../../utils/toaster";

//constant
import { Auth } from "../../themes/ReduxConstant"

// api calling methods
import { adminLoginApi, adminForgetPassApi, adminLogoutApi, adminResetPasswordApi, adminChangePasswordApi, admintokenVerfyApi } from "../Api";

//login
export function* loginSaga(action) {
    try {
        let data = yield call(adminLoginApi, action.payload);
        if (data.status === 0) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.ON_LOGIN_SUCCESS,
                payload: data.result
            })
        }
        else if (data.status === 2) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.RESET
            })
        }
        else {
            toaster(data?.result?.message)
            yield put({
                type: Auth.ON_LOGIN_FAIL,
                payload: data.result
            })
        }
    } catch (error) {

    }
}


// forgetPassword
export function* forgotPasswordSaga(action) {
    try {
        let data = yield call(adminForgetPassApi, action.payload.data);

        if (data.status === 0) {
              toaster(data?.result?.message)
            yield put({
                type: Auth.ON_FORGOTPASS_SUCCESS,
                payload: data?.result
            })
            action.payload.navigate("/login")
        }
        else if (data.status === 2) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.RESET
            })
        }
        else {
            toaster(data?.result?.message)
            yield put({
                type: Auth.ON_FORGOTPASS_FAIL,
                payload: data.result
            })
        }

    } catch (e) {
    }
}


// logout
export function* logoutSaga(action) {
    try {
        let data = yield call(adminLogoutApi);
        if (data.status === 0) {
              toaster(data?.result?.message)
            yield put({
                type: Auth.ON_USER_LOGOUT_SUCCESS,
                payload: data.result
            })
            yield put({
                type: Auth.RESET
            })
        }
        else if (data.status === 2) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.RESET
            })
        }
        else {
            toaster(data?.result?.message)
            yield put({
                type: Auth.ON_USER_LOGOUT_FAIL,
                payload: data.result
            })
        }
    }
    catch (e) {
    }
}
//resetPassword
export function* resetPasswordSaga(action) {
    try {
        let data = yield call(adminResetPasswordApi, action.payload);
        if (data.status === 0) {
              toaster(data?.result?.message)
            yield put({
                type: Auth.ON_PASSWORD_RESET_SUCCESS,
                payload: data.result
            })
            action.payload.navigate("/login")
            // yield put({
            //     type: Auth.RESET
            // })
        }
        else if (data.status === 2) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.RESET
            })
        }
        else {
            toaster(data?.result?.message)
            yield put({
                type: Auth.ON_PASSWORD_RESET_FAIL,
                payload: data.result
            })
        }
    }
    catch (e) {
    }
}

//change Password
export function* changePasswordSaga(action) {
    try {
        let data = yield call(adminChangePasswordApi, action.payload);
        if (data.status === 0) {
              toaster(data?.result?.message)
            yield put({
                type: Auth.ON_PASSWORD_CHANGE_SUCCESS,
                payload: data.result
            })
            yield put({
                type: Auth.RESET
            })
        }
        else if (data.status === 2) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.RESET
            })
        }
        else {
            toaster(data?.result?.message)
            yield put({
                type: Auth.ON_PASSWORD_CHANGE_FAIL,
                payload: data.result
            })
        }
    }
    catch (e) {

    }
}


//verfiy token
export function* tokenVerifSaga(action) {
    try {
        let data = yield call(admintokenVerfyApi, action.payload);
        if (data.status === 0) {
            yield put({
                type: Auth.ON_LINK_VERIFY_SUCCESS,
                payload: data.result
            })
        }
        else if (data.status === 2) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.RESET
            })
        }
        else {
            toaster(data?.result?.message)
            yield put({
                type: Auth.ON_LINK_VERIFY_FAIL,
                payload: data.result
            })
        }
    }
    catch (e) {
    }
}