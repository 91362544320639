import React, { useState, useEffect } from "react";
//ui
import { Layout, Table, Breadcrumb } from "antd";

//Routers
import { Link } from "react-router-dom";

//REDUX
import { userPlaneListAction } from "../../Redux/Actions/AppPurchase";
import { useDispatch, useSelector } from "react-redux";

//layout
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
import { appconstant } from "../../themes/appconstant";
import { columns } from "./constant";
import { RemovePaginationData } from "../../Redux/Actions/userManagmennt";
import { OnPagenationHandelChange, SearchBarFn } from "../../common";

const { Content } = Layout;
let instialData = {
  offset: 0,
  limit: 10,
  search: "",
  sortBy: "",
  order: 1,
};
const InAppPurchaseManagement = () => {
  const [isVisible, setVisible] = useState(false);
  const [data, setData] = useState(instialData);
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userPurchaseReducer);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = appconstant.Titles.InApppurchaseManagement;
    window.scrollTo(0, 0);
    return () => {
      dispatch(RemovePaginationData());
    };
  }, []);

  // the tabel handel change fn.
  const onHandelChange = (pagination, filters, sorter) => {
    OnPagenationHandelChange(pagination,sorter,data,setData,dispatch,userPlaneListAction);
  };
  // this useEffect for dispatch the action when ever their are changes in the search input
  useEffect(() => {
    SearchBarFn(instialData,dispatch,userPlaneListAction,setData,userData?.paginateData?.currentPage,searchInput,data);
  }, [searchInput]);

  return (
    <div>
      <Layout>
        <Sidebar />
        <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
        <Layout className="new_layout">
          <HeaderAdmin handlewClick={handlewClick} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">{appconstant.home}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {appconstant.InApppurchaseManagement}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Content className="new_layout_content">
            <div className="site-layout-background">
              <div className="content-e">
                <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 className="text-white text-capitalize ps-3">
                    {appconstant.InApppurchaseManagement}
                  </h6>
                  <input
                    type="text"
                    className="search-bar iocn-search"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target?.value.trimStart());
                    }}
                  />
                </div>
                <Table
                  dataSource={userData?.Data ?? []}
                  onChange={onHandelChange}
                  columns={columns(userData?.paginateData?.currentPage)}
                  showSorterTooltip={false}
                  pagination={{
                    total: userData?.paginateData?.totalCount,
                    current: userData?.paginateData?.currentPage,
                  }}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default InAppPurchaseManagement;
