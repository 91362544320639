const images = {
  logo: require("../images/logo.png").default,
  plus: require("../images/plus_five.png").default,
  usermessage: require("../images/user_message.png").default,
  login13: require("../images/login13.jpg").default,
  Calender: require("../images/Calendar.svg").default,
  Calender2: require("../images/Calendar2.svg").default,
  Calender3: require("../images/Calendar3.svg").default,
  Calender4: require("../images/Calender4.svg").default,
  Calender5: require("../images/Calendar5.svg").default,
  Calender6: require("../images/Calendar6.svg").default,
  dummyUser: require("../images/dummyUser.png").default,
  CrossImg: require("../images/CrossImg.png").default,
};

export default images;
