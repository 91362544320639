import { Auth } from "../../../themes/ReduxConstant";
export const loginAction = (data) => {
    return {
        type: Auth.ON_LOGIN_LOAD,
        payload: data
    }
};
export const ForgotPasswordAction = (data) => {
    return {
        type: Auth.ON_FORGOTPASS_LOAD,
        payload: data
    }
};
export const logoutAction = (data) => {
    return {
        type: Auth.ON_USER_LOGOUT_LOAD,
        payload:data
    }
};
export const resetPasswordAction = (data) => {
    return {
        type: Auth.ON_PASSWORD_RESET_LOAD,
       payload:data
    }
};
export const changePasswordAction = (data) => {
    return {
        type: Auth.ON_PASSWORD_CHANGE_LOAD,
       payload:data
    }
};
export const resetPasswordLinkVerify = (data) => {
    return {
        type: Auth.ON_LINK_VERIFY_LOAD,
       payload:data
    }
};
