import React, { useState, useEffect } from "react";

//UI
import { Layout, Breadcrumb, Spin } from "antd";

//routers
import { Link, useLocation, useNavigate } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";
import { viewUserAction } from "../../Redux/Actions/userManagmennt";
import { useSelector } from "react-redux";

//layouts
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";
//themes
import { appconstant } from "../../themes/appconstant";
import { InputFields, InteresteFn, colorObj, ValuesTopicFn } from "./constant";
import { IMAGE_URL } from "../../themes/ReduxConstant";
import moment from "moment";
import { Chip } from "@mui/material";
const { Content } = Layout;
const UserView = () => {
  const [isVisible, setVisible] = useState(false);
  const [Userheight, setUserHeight] = useState([]);
  const [ImageOnLoad, SetOnLoad] = useState(true);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = appconstant.Titles.ViewUser;
    window.scrollTo(0, 0);
    dispatch(viewUserAction({ userId: state?.id, navigate: navigate }));
  }, []);
  const { userData } = useSelector((state) => state.userManagmenntReducer);
  const images = userData?.info?.media?.filter((item) => item.isImage);
  const video = userData?.info?.media?.filter((item) => item.isVideo);
  let date = moment(userData?.info?.dob).format("DD/MM/YYYY");
  const image = () => {
    return images?.length > 0
      ? images?.map((item) => {
          return (
            <div className="iamge-t iamge viewImages">
              <Spin spinning={ImageOnLoad}>
                <img
                  src={IMAGE_URL + item.fileName}
                  onLoad={() => SetOnLoad(false)}
                  alt="user images"
                  width={"100%"}
                  height={"100%"}
                />
              </Spin>
            </div>
          );
        })
      : "No image is uploaded.";
  };
  const Video = () => {
    return video?.length > 0 ? (
      video?.map((item) => (
        <div className="InnerVideoDiv">
          <video
            controls
            width={"100%"}
            height="100%"
            style={{ marginTop: "-1px" }}
          >
            <source
              src={IMAGE_URL + item?.fileName}
              type="video/mp4"
              width="100%"
            />
          </video>
        </div>
      ))
    ) : (
      <div>No video is Uploaded.</div>
    );
  };
  let counts = 0;
  useEffect(() => {
    if (userData?.depthInfo?.height) {
      let feet = (userData?.depthInfo?.height / 12).toString().split(".");
      let inch = (userData?.depthInfo?.height % 12).toString().split(".");
      setUserHeight(`${feet[0]}'${inch[0]}"`);
    } else {
      setUserHeight(`3'0"`);
    }
    userData?.depthInfo?.interests &&
      Object?.values(userData?.depthInfo?.interests)?.forEach((item) => {
        if (item?.length !== 0) {
          counts += item?.length;
        }
      });
    setCount(counts);
  }, [userData]);
  const Show = (value) => {
    return (
      <div className="textTagDiv">
        {value?.map((item, index) => {
          return (
            <div
              className="textTag"
              style={{
                background: colorObj[index].background,
                border: `1px solid ${colorObj[index].border}`,
                color: `${colorObj[index].text}`,
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">{appconstant.home}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/usermanagement">{appconstant.UserManagement}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{appconstant.UserDetails}</Breadcrumb.Item>
        </Breadcrumb>
        <Content className="new_layout_content">
          <div className="site-layout-background">
            <div className="content-e">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                <h6 className="text-white text-capitalize ps-3">
                  {appconstant.UserDetails}
                </h6>
              </div>
              <div className="wrapper_line view_modules view">
                <div className="">
                  <label>{appconstant.Photos}</label>
                  {image() ? (
                    <div className="wrapper_multi space_l">{image()}</div>
                  ) : (
                    <p style={{ padding: "0px" }}>No image uploaded.</p>
                  )}
                </div>
                <InputFields
                  name={appconstant?.fullName}
                  value={userData?.info?.name}
                />
                <InputFields name={appconstant?.Gender} value={userData?.info?.gender} />
                <InputFields
                  name={appconstant?.phonenumber}
                  value={
                    userData?.phone
                      ? `${userData?.countryCode} ${userData?.phone}`
                      : "N/A"
                  }
                />
                <div>
                  <label>{appconstant?.Genderprefrence}</label>
                  <p className={`paragraph`}>
                    {userData?.info?.attractedTo?.length > 0
                      ? userData?.info?.attractedTo.map((item) => (
                          <Chip
                            key={item}
                            label={item === "Other" ? "Non-binary" : item}
                            style={{ margin: "0px 5px" }}
                          />
                        ))
                      : "N/A"}
                  </p>
                </div>
                <InputFields name={appconstant?.Dateofbirth} value={date} />
                <InputFields name={appconstant?.Height} value={Userheight} />
                <InputFields
                  name={appconstant?.City}
                  value={userData?.depthInfo?.city}
                />
                <InputFields
                  name={appconstant?.Build}
                  value={userData?.depthInfo?.fitness}
                />
                <InputFields
                  name={appconstant?.Religion}
                  value={userData?.depthInfo?.religion}
                />
                <div style={{ width: "100%" }}>
                  <label>{appconstant?.Vices}</label>
                  <div style={{ display: "flex" }}>
                    <InputFields
                      name={"Drinking"}
                      value={userData?.depthInfo?.vices?.drinking}
                      OuterClass="Inner_vices_div"
                    />
                    <InputFields
                      name={"Gambling"}
                      value={userData?.depthInfo?.vices?.gambling}
                      OuterClass="Inner_vices_div"
                    />
                    <InputFields
                      name={"Smoking"}
                      value={userData?.depthInfo?.vices?.smoking}
                      OuterClass="Inner_vices_div"
                    />
                  </div>
                </div>
                <InputFields
                  name={appconstant?.Education}
                  value={userData?.depthInfo?.education}
                />
                <InputFields
                  name={appconstant?.Employment}
                  value={userData?.depthInfo?.employment}
                />
                <div>
                  <label>{appconstant?.Music}</label>
                  {userData?.depthInfo?.music.length > 0 ? (
                    <div className="ShowDiv">
                      {Show(userData?.depthInfo?.music)}
                    </div>
                  ) : (
                    <p style={{ margin: "10px 0px", height: "auto" }}>
                      No music is added.
                    </p>
                  )}
                </div>
                <div>
                  <label>{appconstant?.Movies}</label>
                  {userData?.depthInfo?.movies.length > 0 ? (
                    <div className="ShowDiv">
                      {Show(userData?.depthInfo?.movies)}
                    </div>
                  ) : (
                    <p style={{ margin: "10px 0px", height: "auto" }}>
                      No movie is added.
                    </p>
                  )}
                </div>

                <InputFields
                  name={appconstant?.Datingprefrence}
                  value={userData?.depthInfo?.intentions}
                />
                <InputFields
                  name={appconstant?.Marriageprefrence}
                  value={userData?.depthInfo?.preference?.marriage}
                />
                <InputFields
                  name={appconstant?.Childprefrence}
                  value={userData?.depthInfo?.preference?.child}
                />
                <InputFields
                  name={appconstant?.Location}
                  value={userData?.location?.coordinates.join(" , ")}
                />
                <InputFields
                  className="bioInputFiled"
                  name={appconstant?.Bio}
                  value={userData?.depthInfo?.bio}
                />
                <InputFields
                  name={appconstant?.LuvBux}
                  value={userData?.luvbuxCount}
                />
                <div>
                  <label className="option_lite f ">Interests</label>
                  {count > 0 ? (
                    userData?.depthInfo?.interests &&
                    InteresteFn(userData?.depthInfo?.interests)
                  ) : (
                    <div>No interest is selected.</div>
                  )}
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <label className="option_lite f ">Values</label>
                </div>
                {userData?.depthInfo?.values?.length > 0 ? (
                  userData?.depthInfo?.values &&
                  ValuesTopicFn(userData?.depthInfo?.values)
                ) : (
                  <div style={{ margin: "-10px 0px 2px 0px" }}>
                    No values is selected.
                  </div>
                )}
                <div className="videoDiv">
                  <label className="VideoLabel">{appconstant.Video}</label>
                  <div className="VideoFnDiv">{Video()}</div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default UserView;
