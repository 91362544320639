import { Tooltip } from "antd";
import moment from "moment";

export const columns = (currentPage) => {
  return [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) =>
        currentPage > 1 ? (currentPage - 1) * 10 + (index + 1) : index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value, item, index) => {
        return item?.name ? (
          <Tooltip title={item?.name} className="Questions tabelColume">
            <span>
              <span tabelColume>{item?.name}</span>
            </span>
          </Tooltip>
        ) : (
          item?.userName
        );
      },
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
      sorter: true,
      render: (value, item, index) => {
        return item?.packageName ? (
          <Tooltip title={item?.packageName} className="Questions tabelColume">
            <span>
              <span tabelColume>{item?.packageName}</span>
            </span>
          </Tooltip>
        ) : (
          "N/A"
        );
      },
    },
    {
      title: "Transaction ID ",
      dataIndex: "transactionId",
      key: "transection",
      sorter: true,
      render: (value, item, index) => {
        return item?.transactionId ? (
          <Tooltip
            title={item?.transactionId}
            className="Questions tabelColume"
          >
            <span tabelColume>{item?.transactionId}</span>
          </Tooltip>
        ) : (
          "N/A"
        );
      },
    },
    {
      title: "Date & Time ",
      dataIndex: "createdAt",
      key: "date",
      sorter: false,
      render: (value, item, index) => {
        return <> {moment(item?.createdAt).format("YYYY-MM-DD hh:mm:ss A")}</>;
      },
    },
  ];
};
