import React, { useState, useEffect } from "react";

//UI
import { Layout, Table, Breadcrumb } from "antd";

//routers
import { Link, useLocation } from "react-router-dom";

//layouts
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";

//themes
import { appconstant } from "../../themes/appconstant";
import { TabelHead } from "./constant";
import { RemovePaginationData } from "../../Redux/Actions/userManagmennt";
import { useDispatch, useSelector } from "react-redux";
import {
  QuizQuestionDeleteAction,
  fandomQuizQuestionload,
  QuizQuestionEditAction,
  fandomQuizQuestionAddload,
} from "../../Redux/Actions/FandomManagement";
import { QuestionModel } from "./QuestionModel";
import { OnPagenationHandelChange, SearchBarFn } from "../../common";
const { Content } = Layout;
const instialData = {
  sortBy: "",
  order: "",
  search: "",
  offset: 0,
  limit: 10,
  fandomId: "",
};
const FandomQuizManagementEditQuiz = () => {
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState(instialData);
  const [EditClick, SetisEditClick] = useState(false);
  const [AddClick, SetisAddClick] = useState(false);
  const [QuestionData, setQuestionData] = useState(true);
  const dispatch = useDispatch();
  const [isVisible, setVisible] = useState(false);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = appconstant.Titles.EditQuiz;
    return () => {
      dispatch(RemovePaginationData());
    };
  }, []);

  const reducerData = useSelector((state) => state.fandomMAnagementReducer);
  const { state } = useLocation();
  useEffect(() => {
    SearchBarFn(
      instialData,
      dispatch,
      fandomQuizQuestionload,
      setData,
      reducerData?.paginateData?.currentPage,
      searchInput,
      data,
      state?.id?._id
    );
  }, [searchInput]);
  const onButtonClick = (data) => {
    SetisEditClick(true);
    setQuestionData({ ...data?.id });
  };

  // this function dispatch action when user click on the delete button
  const cb = (isButtonClick, name, userData) => {
    if (
      reducerData?.paginateQuizData?.totalCount - 1 ===
      (reducerData?.paginateQuizData?.currentPage - 1) * 10
    ) {
      data.offset = (reducerData?.paginateQuizData?.currentPage - 2) * 10;
    }
    isButtonClick &&
      dispatch(QuizQuestionDeleteAction({ userId: userData._id, data: data }));
  };
  const handleChange = (pagination, filters, sorter) => {
    OnPagenationHandelChange(
      pagination,
      sorter,
      data,
      setData,
      dispatch,
      fandomQuizQuestionload,
      state?.id?._id
    );
  };
  const QuestionCb = (value) => {
    SetisEditClick(value?.click);
    Object.keys(value?.data).length > 0 &&
      dispatch(QuizQuestionEditAction({ Questiondata: value, data: data }));
  };
  const AddQuestionCb = (value) => {
    SetisAddClick(value?.click);
    Object.keys(value?.data).length > 0 &&
      dispatch(
        fandomQuizQuestionAddload({ Questiondata: value.data, data: data })
      );
  };
  return (
    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">{appconstant.home}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/fandom">{appconstant.fandomquizmanagement}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit Quiz</Breadcrumb.Item>
        </Breadcrumb>
        <Content className="new_layout_content">
          <div className="form-group">
            <div className="category_d">Category name: {state?.id?.topic}</div>
          </div>
          <div className="top_button_item top_text">
            <div className="true_legend">
              <button
                type="submit"
                className="button-list top_new "
                onClick={() => SetisAddClick(true)}
              >
                Add Question
              </button>
              <span className="custom_p">Max 50 questions can be added.</span>
            </div>
          </div>
          <div className="site-layout-background">
            <div className="content-e">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                <h6 className="text-white text-capitalize ps-3">
                  {appconstant.fandomquizmanagement}
                </h6>
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value.trimStart());
                  }}
                />
              </div>
              <Table
                dataSource={reducerData.questionList}
                columns={TabelHead(
                  (isButtonClick, name, userData) =>
                    cb(isButtonClick, name, userData),
                  (data, name) => onButtonClick(data, name),
                  reducerData?.paginateQuizData?.currentPage,
                  true
                )}
                showSorterTooltip={false}
                onChange={handleChange}
                pagination={{
                  current: reducerData?.paginateQuizData?.currentPage ?? 1,
                  total: reducerData?.paginateQuizData?.totalCount,
                }}
              />
            </div>
          </div>
          {EditClick && (
            <QuestionModel
              cb={QuestionCb}
              isClick={EditClick}
              title={"Edit Question"}
              data={QuestionData}
            />
          )}
          {AddClick && (
            <QuestionModel
              cb={AddQuestionCb}
              isClick={AddClick}
              title={"Add Question"}
              data={{ ID: state?.id?._id }}
            />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};
export default FandomQuizManagementEditQuiz;
