import { all, takeEvery } from "@redux-saga/core/effects";

//redux Constant
import {
  Auth,
  Dashboard,
  fandomQuiz,
  Intrests,
  UserManagement,
  Userpurchase,
} from "../../themes/ReduxConstant";

//saga file
import {
  forgotPasswordSaga,
  loginSaga,
  logoutSaga,
  resetPasswordSaga,
  changePasswordSaga,
  tokenVerifSaga,
} from "./AuthSaga";
import { dashboardSaga } from "./dashBoardSaga";
import {
  fandomQuizDeleteSaga,
  fandomQuizLoadSaga,
  fandomQuizQuestionAddSaga,
  fandomQuizQuestionDeleteSaga,
  fandomQuizQuestionEditSaga,
  fandomQuizQuestionListSaga,
} from "./fandomManagement ";
import {
  userListSaga,
  userDeleteSaga,
  userBlockSaga,
  userViewSaga,
  userEditSaga,
  DropDownSaga,
  InterestsSaga,
} from "./userManagment";
import { userPurchaseListSaga } from "./userPurchases";

export function* rootSaga() {
  yield all([
    yield takeEvery(Auth.ON_LOGIN_LOAD, loginSaga),
    yield takeEvery(Auth.ON_FORGOTPASS_LOAD, forgotPasswordSaga),
    yield takeEvery(Auth.ON_USER_LOGOUT_LOAD, logoutSaga),
    yield takeEvery(Auth.ON_PASSWORD_RESET_LOAD, resetPasswordSaga),
    yield takeEvery(Auth.ON_PASSWORD_CHANGE_LOAD, changePasswordSaga),
    yield takeEvery(Auth.ON_LINK_VERIFY_LOAD, tokenVerifSaga),
    yield takeEvery(Dashboard.REGISTER_USERS_DATA_LOAD, dashboardSaga),
    yield takeEvery(UserManagement.ON_USER_LOAD, userListSaga),
    yield takeEvery(UserManagement.ON_USER_DELETE_LOAD, userDeleteSaga),
    yield takeEvery(UserManagement.ON_USER_BLOCK_LOAD, userBlockSaga),
    yield takeEvery(UserManagement.ON_USER_VIEW_LOAD, userViewSaga),
    yield takeEvery(UserManagement.ON_USER_EDIT_LOAD, userEditSaga),
    yield takeEvery(UserManagement.ON_DROPDOWN_LOAD, DropDownSaga),
    yield takeEvery(Userpurchase.USER_PURCHASE_LOAD, userPurchaseListSaga),
    yield takeEvery(Intrests.INTRESTS_LOAD, InterestsSaga),
    yield takeEvery(fandomQuiz.FANDOM_QUIZ_TOPIC_LOAD, fandomQuizLoadSaga),
    yield takeEvery(
      fandomQuiz.FANDOM_QUIZ_TOPIC_DELETE_LOAD,
      fandomQuizDeleteSaga
    ),
    yield takeEvery(
      fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_LOAD,
      fandomQuizQuestionListSaga
    ),
    yield takeEvery(
      fandomQuiz.FANDOM_QUIZ_QUESTION_EDIT_LOAD,
      fandomQuizQuestionEditSaga
    ),
    yield takeEvery(
      fandomQuiz.FANDOM_QUIZ_QUESTION_ADD_LOAD,
      fandomQuizQuestionAddSaga
    ),
    yield takeEvery(
      fandomQuiz.FANDOM_QUIZ_QUESTION_DELETE_LOAD,
      fandomQuizQuestionDeleteSaga
    ),
  ]);
}
