import { Auth } from '../../../themes/ReduxConstant'
const  instialState = {
    isLoading: false,
    message: "",
    isSuccess: false,
    data: null,
    token: null,
    isVerfy: false
}
export const AuthReducer = (state = instialState, action) => {
    switch (action.type) {
        //login 
        case Auth.ON_LOGIN_LOAD:
            return {
                ...state,
                isLoading: true
            }
        case Auth.ON_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                token: action.payload.data.token,
                isAuth: action.payload.success,
                message: action.payload.message,
            }
        case Auth.ON_LOGIN_FAIL:
            return {
                ...state,
                token: null,
                isAuth: false,
                isLoading: false,
                message: action.payload.message,
            }
        //forgetPassword
        case Auth.ON_FORGOTPASS_LOAD:
            return {
                ...state,
                isLoading: true,
            }
        case Auth.ON_FORGOTPASS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            }
        case Auth.ON_FORGOTPASS_FAIL:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
            }
        //userLogout
        case Auth.ON_USER_LOGOUT_LOAD:
            return {
                ...state,
                isLoading: true,
            }
        case Auth.ON_USER_LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                token: null,
                message: action.payload.message
            }
        case Auth.ON_USER_LOGOUT_FAIL:
            return {
                ...state,
                isLoading: false,
            }
        //resetPassword
        case Auth.ON_PASSWORD_RESET_LOAD:
            return {
                ...state,
                isLoading: true,
            }
        case Auth.ON_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                token: null,
                message: action.payload.message
            }
        case Auth.ON_PASSWORD_RESET_FAIL:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
            }
        //changePassword
        case Auth.ON_PASSWORD_CHANGE_LOAD:
            return {
                ...state,
                isLoading: true,
            }
        case Auth.ON_PASSWORD_CHANGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                token: null,
                message: action.payload.message
            }
        case Auth.ON_PASSWORD_CHANGE_FAIL:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
            }
        //token verify 
        case Auth.ON_LINK_VERIFY_LOAD:
            return {
                ...state,
                isLoading: true,
            }
        case Auth.ON_LINK_VERIFY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isVerfy: action.payload.success,
                token: null,
                message: action.payload.message,
            }
        case Auth.ON_LINK_VERIFY_FAIL:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
                isVerfy: false
            }
        default: return state
    }

}