import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { InterestsAction } from "../../Redux/Actions/userManagmennt";
import { toaster } from "../../utils/toaster";
import { IntrestIcon } from "./constant";
const TopicViewModel = ({
  PreSelectedInterests,
  InterestCb,
  isVisibal,
  SelectedInterestCb,
  SelectedKey,
  heading,
  totalLenght,
}) => {
  const { Interests } = useSelector((state) => state.userManagmenntReducer);
  const [obj, setobj] = useState([]);
  const [count, setCount] = useState(0);
  const [Data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(InterestsAction());
    PreSelectedInterests && setobj([...PreSelectedInterests]);
  }, []);
  useEffect(() => {
    if (SelectedKey == "values") {
      setData(Interests.values);
    } else if (SelectedKey == "topic") {
      setData(Interests.topics);
    }
  }, [Interests]);

  const OnInterestsSelect = (value) => {
    let index = obj?.findIndex((item) => item == value);
    let newLenght = +totalLenght + 1;
    if (index === -1 && count < totalLenght) {
      let data = [...obj];
      data?.push(value);
      setobj(data);
    } else if (index !== -1 && count < newLenght) {
      let data = [...obj];
      data?.splice(index, 1);
      setobj(data);
    } else if (count < newLenght) {
      toaster(`You can only select up to  ${totalLenght} ${SelectedKey}.`);
    }
  };

  useEffect(() => {
    let counts = obj.length;
    setCount(counts);
  }, [OnInterestsSelect]);
  const onOkClick = () => {
    InterestCb(false, SelectedKey);
    SelectedInterestCb(obj, SelectedKey);
  };
  const onCancelClick = () => {
    InterestCb(false, SelectedKey);
    SelectedInterestCb(PreSelectedInterests, SelectedKey);
  };
  return (
    <>
      <Modal
        title={heading}
        centered
        visible={isVisibal}
        onOk={onOkClick}
        onCancel={onCancelClick}
        width={700}
        style={{ backgroundColor: "#e9e8f6" }}
        className={"InterestsModel"}
      >
        {Interests
          ? Data?.map((item, index) => {
              let isExist =
                obj?.length > 0 &&
                obj?.find((selected) => selected == item) !== undefined
                  ? true
                  : false;

              return (
                <>
                  {
                    <button
                      type="button"
                      className="item_interests"
                      style={{
                        backgroundColor: "#BEB6FC",
                        BoxShadow: isExist
                          ? `0 12px 20px -10px rgb(176 174 245), 0 4px
                            20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(122 142
                            215)`
                          : "none",
                        border: isExist ? " 3px solid #778EF5" : "none",
                      }}
                      onClick={() => OnInterestsSelect(item, index)}
                    >
                      <div className="InterestsDiv">
                        <div className="InterestsIconStyle">
                          <img
                            src={IntrestIcon[item]}
                            width="100%"
                            height="100%"
                            alt=""
                          />
                        </div>
                        {item}
                      </div>
                    </button>
                  }
                </>
              );
            })
          : ""}
      </Modal>
    </>
  );
};
export default TopicViewModel;
