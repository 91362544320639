import 'antd/dist/antd.css';
//routes
import RoutesNew from './Route/Routes';

//css
import './App.css';
import '../src/css/style.css';
import '../src/css/responsive.css';


const App = () => {
  return (
      <RoutesNew />
  );
}

export default App;
