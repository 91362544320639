import React, { useEffect } from "react"
//UI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import images from '../../themes/appImage'

//ROUTERS
import {
    Link, useNavigate
} from "react-router-dom";

//formik
import { Formik } from 'formik';

//redux
import { useDispatch, } from "react-redux";
import { ForgotPasswordAction } from '../../Redux/Actions/Auth'

//Themes
import { appconstant } from "../../themes/appconstant";
import {Textfiled} from "../../common";

//utils
import { validationSchema } from "../../utils/ValidationSchema";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    useEffect(() => {
        document.title = appconstant.Titles.ForgotPassword;
        window.scrollTo(0, 0)

    }, [])
    const onSubmit = (data) => {
        dispatch(ForgotPasswordAction({ data, navigate }))
    }
    return (
        <div className="image-bg">
            <div className="page-header">
                <div className="line_true">
                    <div className="content-e ">

                        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 fg_pass"  >
                            <h6 className="text-white line_element text-capitalize ps-3 d-flex">
                                <Link to="/login"> <FontAwesomeIcon icon={faArrowLeft} /></Link>{appconstant.ForgotPassword}</h6>
                            <h6 className="text-white text-capitalize ps-3 text-center">Forgot Password</h6>
                        </div>
                        <div className="wrapper_line">
                            <div className="text-center cost">
                                <img src={images.logo}  alt={"logo"}/>                               
                            </div>
                            <Formik initialValues={{
                                email: "",
                            }}
                                validationSchema={validationSchema("forgetPassword")}
                                onSubmit={onSubmit}
                            >
                                {({
                                    values,
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    touched,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label>{appconstant.EmailAddress}</label>
                                            <div className="form-group">

                                                <Textfiled
                                                    error={Boolean(touched.email && errors.email)}
                                                    helperText={touched.email && errors.email}
                                                    label='Email Address'
                                                    variant='standard'
                                                    className='filed-control'
                                                    type='text'
                                                    name="email"
                                                    value={values.email.trim()}
                                                    onChangeText={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-center_login'>
                                            <button type="submit" className="button-list" >Send</button>
                                        </div>
                                    </form>)}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword;
