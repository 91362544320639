import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { InterestsAction } from "../../Redux/Actions/userManagmennt";
import { toaster } from "../../utils/toaster";
import { IntresteName, IntrestIcon } from "./constant";
const IntrestsModels = ({
  PreSelectedInterests,
  InterestCb,
  isVisibal,
  SelectedInterestCb,
  SelectedKey,
}) => {
  const { Interests } = useSelector((state) => state.userManagmenntReducer);
  const [obj, setobj] = useState({});
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(InterestsAction());
    setobj(JSON.parse(JSON.stringify(PreSelectedInterests)));
  }, []);
  let counts = 0;
  const OnInterestsSelect = (value, key) => {
    let index =
      obj[key] && Object.values(obj[key])?.findIndex((item) => item === value);
    if (index === -1 && count < 5) {
      let data = { ...obj };
      data[key]?.push(value);
      setobj(data);
    } else if (index !== -1 && count < 6) {
      let data = { ...obj };
      data[key]?.splice(index, 1);
      setobj(data);
    } else if (count < 6) {
      toaster("You can only select up to 5 interests.");
    }
  };
  useEffect(() => {
    for (let i of obj && Object?.values(obj)) {
      if (i?.length !== 0 && counts < 5) {
        counts += i?.length;
      }
    }
    setCount(counts);
  }, [OnInterestsSelect]);
  const onOkClick = () => {
    InterestCb(false, SelectedKey);
    SelectedInterestCb(obj, SelectedKey);
  };
  const onCancelClick = () => {
    InterestCb(false, SelectedKey);
    SelectedInterestCb(PreSelectedInterests, SelectedKey);
  };
  return (
    <>
      <Modal
        title="Interests (Any 5)"
        centered
        visible={isVisibal}
        onOk={onOkClick}
        onCancel={onCancelClick}
        width={700}
        style={{ backgroundColor: "#e9e8f6" }}
        className={"InterestsModel"}
      >
        {Interests?.intrest
          ? Object.entries(Interests?.intrest)?.map(([key, value]) => {
              return (
                <>
                  <div
                    style={{
                      fontSize: "17px",
                      fontWeight: "600",
                      margin: "10px 0px",
                    }}
                  >
                    {IntresteName[key]}
                  </div>
                  {value?.length > 0 &&
                    value?.map((IntrestsValue) => {
                      let isExist =
                        obj[key]?.length > 0 &&
                        Object?.values(obj[key])?.find(
                          (item) => item === IntrestsValue
                        ) !== undefined
                          ? true
                          : false;
                      return (
                        <>
                          {
                            <button
                              type="button"
                              className="item_interests"
                              style={{
                                backgroundColor: "#BEB6FC",
                                BoxShadow: isExist
                                  ? `0 12px 20px -10px rgb(176 174 245), 0 4px
                            20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(122 142
                            215)`
                                  : "none",
                                border: isExist ? " 3px solid #778EF5" : "none",
                              }}
                              onClick={() =>
                                OnInterestsSelect(IntrestsValue, key)
                              }
                            >
                              <div className="InterestsDiv">
                                <div className="InterestsIconStyle">
                                  <img
                                    src={IntrestIcon[IntrestsValue]}
                                    width="100%"
                                    height="100%"
                                    alt=""
                                  />
                                </div>
                                {IntrestsValue}
                              </div>
                            </button>
                          }
                        </>
                      );
                    })}
                  <hr
                    style={{
                      border: "1px solid #d0cffa",
                    }}
                  />
                </>
              );
            })
          : ""}
      </Modal>
    </>
  );
};
export default IntrestsModels;
