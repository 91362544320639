//ui
import { faHome, faUser, faUnlock, faSignOutAlt, faQuestionCircle, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const navbar = (cb) => {
  switch (window.location.pathname) {
    case "/dashboard":
      cb('1')
      break;
    case "/usermanagement":
      cb('2')
      break;
    case "/userview":
      cb('2')
      break;
    case "/useredit":
      cb('2')
      break;
    case "/gameplayed":
      cb('2')
      break;
    case "/gameorganised":
      cb('2')
      break;
    case "/adduser":
      cb('2')
      break;
    case "/newmanagement":
      cb('2')
      break;

    case "/fandom":
      cb('3')
      break;
    case "/fandomquiz":
      cb('3')
      break;

    case "/fandomeditquiz":
      cb('3')
      break;

    case "/purchase":
      cb('4')
      break;
    case "/changepassword":
      cb('5')
      break;
    default: return cb("1")
  }
}

export const navBarItems = (cb) => {

  return [
    {
      key: '1',
      icon: <FontAwesomeIcon icon={faHome} />,
      label: 'Dashboard',
      onClick: () => cb(1, "/"),
      className: "line_dash"
    },
    {
      key: '2',
      icon: <FontAwesomeIcon icon={faUser} />,
      label: 'User Management',
      onClick: (e) => {
        cb(2, '/usermanagement')
      },
      className: "line_dash"
    },
    {
      key: '3',
      icon: <FontAwesomeIcon icon={faQuestionCircle} />,
      label: 'Fandom Quiz Management',
      onClick: (e) => {
        cb(3, '/fandom')
      },
      className: "line_dash"
    },
    {
      key: '4',
      icon: <FontAwesomeIcon icon={faShoppingCart} />,
      label: 'In-App purchase Management',
      onClick: (e) => {
        cb(4, '/purchase')

      },
      className: "line_dash"
    },
    {
      key: '5',
      icon: <FontAwesomeIcon icon={faUnlock} />,
      label: 'Change Password',
      onClick: (e) => {
        cb(5, '/changepassword')
      },
      className: "line_dash"
    },
    {
      key: '6',
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      label: 'Logout',
      onClick: (e) => {
        cb(6, '');
      },
      className: "line_dash"
    },
  ]
}