import {
  fandomQuiz,
  UserManagement,
} from "../../../themes/ReduxConstant";

const instialValue = {
  isLoading: false,
  QuizList: [],
  paginateData: {},
  paginateQuizData: {},
  questionList: [],
};
export const fandomMAnagementReducer = (state = instialValue, action) => {
  switch (action.type) {
    // user list
    case fandomQuiz.FANDOM_QUIZ_TOPIC_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case fandomQuiz.FANDOM_QUIZ_TOPIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        QuizList: action.payload?.data,
        paginateData: action.payload?.paginateData,
      };
    case fandomQuiz.FANDOM_QUIZ_TOPIC_FAIL:
      return {
        ...state,
        QuizList: [],
      };

    case fandomQuiz.FANDOM_QUIZ_TOPIC_DELETE_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case fandomQuiz.FANDOM_QUIZ_TOPIC_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case fandomQuiz.FANDOM_QUIZ_TOPIC_DELETE_FAIL:
      return {
        ...state,
      };

    case fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionList: action.payload?.data,
        paginateQuizData: action.payload?.paginateData,
      };
    case fandomQuiz.FANDOM_QUIZ_QUESTION_LIST_FAIL:
      return {
        ...state,
        questionList:[]
      };
    case UserManagement.ON_PAGE_LEAVE_LOAD:
      return {
        ...state,
        paginateData: {},
        paginateQuizData: {},
      };
    default:
      return state;
  }
};
