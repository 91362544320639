// constant
import { Intrests, UserManagement } from "../../../themes/ReduxConstant";

const instialValue = {
  isLoading: false,
  userList: [],
  paginateData: {},
  isBlock: false,
  userData: {},
  dropdown: {},
  Interests: {},
};
export const userManagmenntReducer = (state = instialValue, action) => {
  switch (action.type) {
    // user list
    case UserManagement.ON_USER_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case UserManagement.ON_USER_SUCCES:
      return {
        ...state,
        isLoading: false,
        userList: action.payload?.data,
        paginateData: action.payload?.paginateData,
      };
    case UserManagement.ON_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        userList: [], 
      };
    // user delete
    case UserManagement.ON_USER_DELETE_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case UserManagement.ON_USER_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UserManagement.ON_USER_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    // user block
    case UserManagement.ON_USER_BLOCK_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case UserManagement.ON_USER_BLOCK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isBlock: true,
      };
    case UserManagement.ON_USER_BLOCK_FAIL:
      return {
        ...state,
        isLoading: false,
        isBlock: false,
      };
    // user edit
    case UserManagement.ON_USER_EDIT_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case UserManagement.ON_USER_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userData: action.payload.data,
      };
    case UserManagement.ON_USER_EDIT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    //user view
    case UserManagement.ON_USER_VIEW_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case UserManagement.ON_USER_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userData: action.payload?.data,
      };
    case UserManagement.ON_USER_VIEW_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    //on page leave
    case UserManagement.ON_PAGE_LEAVE_LOAD:
      return {
        ...state,
        paginateData: {},
        userList: [],
        userData: {},
      };
    //dropdown
    case UserManagement.ON_DROPDOWN_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case UserManagement.ON_DROPDOWN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dropdown: action.payload?.data,
      };

    case UserManagement.ON_DROPDOWN_FAIL:
      return {
        ...state,
        isLoading: false,
        // dropdown: {},
      };
    //interests
    case Intrests.INTRESTS_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case Intrests.INTRESTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Interests: action.payload,
      };
    case Intrests.INTRESTS_FAIL:
      return {
        ...state,
        isLoading: false,
        Interests: {},
      };
    default:
      return state;
  }
};
