import { put, call } from "@redux-saga/core/effects";

// utils
import { toaster } from "../../utils/toaster";

//constant
import { Dashboard, Auth } from "../../themes/ReduxConstant"

// api calling methods
import { DashboardRegisterUser } from "../Api";

//login
export function* dashboardSaga(action) {
    try {
        let data = yield call(DashboardRegisterUser, action.payload);
        if (data.status === 0) {
            yield put({
                type: Dashboard.REGISTER_USERS_DATA_SUCCESS,
                payload: data.result.data
            })
        }
        else if (data.status === 2) {
            toaster(data?.result?.message)
            yield put({
                type: Auth.RESET
            })
        }
        else {
            toaster(data?.result?.message)
            yield put({
                type: Dashboard.REGISTER_USERS_DATA_FAIL,
                payload: data.result
            })
        }
    } catch (error) {

    }
}
