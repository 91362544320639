import React, { useState, useEffect } from "react";

//UI
import { Layout, Menu, Drawer } from "antd";

//reducx
import { useDispatch } from "react-redux";
import { logoutAction } from "../Redux/Actions/Auth";

//routers
import { Link, useNavigate } from "react-router-dom";

//utils
import {navBarItems } from "../utils/navbar";

//themes
import images from "../themes/appImage";
import { Model } from "../utils/model";

const { Sider } = Layout;

const MobileSidebar = (props) => {
  const [selecteditem, setselecteditem] = useState(null);
  let navigate = useNavigate();
  const [Logout, setLogout] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Logout) {
      onNavBarItemClick();
    }
  }, [Logout]);
  const onNavBarItemClick = (index, path) => {
    if (path) {
      navigate(path);
      setselecteditem(index);
    } else {
      !Logout &&
        index === 6 &&
        Model("Logout", "Are you sure, you want to logout?", (isClick) =>
          setLogout(isClick)
        );
      Logout && dispatch(logoutAction({ navigate }));
    }
  };
  return (
    <Drawer
      placement="right"
      onClose={props.handlewClick}
      visible={props.isVisible}
    >
      <Sider
        className="sider_left"
        collapsedWidth="0"
        width="250"
        height="100vh"
      >
        <div className="logo">
          <Link to="/dashboard">
            <img src={images.logo} alt=""/>
          </Link>
        </div>

        <Menu
          // className="asdf"
          theme="dark"
          mode="inline"
          selectedKeys={[`${selecteditem}`]}
          items={navBarItems((index, path) => onNavBarItemClick(index, path))}
          key={Math.random().toString()}
        />
      </Sider>
    </Drawer>
  );
};
export default MobileSidebar;
