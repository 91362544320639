import React, { useState, useEffect } from "react";

//UI
import { Layout, Breadcrumb } from "antd";

//routers
import { Link } from "react-router-dom";

//redux
import { usersAction } from "../../Redux/Actions/Dashboard";
import { useDispatch, useSelector } from "react-redux";

//layouts
import HeaderAdmin from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import MobileSidebar from "../../layout/mobilesidebar";

//themes
import { appconstant, items } from "../../themes/appconstant";

const { Content } = Layout;

const Dashboard = () => {
  const [isVisible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.dashboardReducer);

  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = appconstant.Titles.Dashboard;
    window.history?.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history?.pushState(null, document.title, window.location.href);
    });
    dispatch(usersAction());
    return () => null;
  }, []);
  return (
    <Layout>
      <Sidebar />
      <MobileSidebar isVisible={isVisible} handlewClick={handlewClick} />
      <Layout className="new_layout">
        <HeaderAdmin handlewClick={handlewClick} />
        <Content className="new_layout_content">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard" className="breadcrum_item_link">
                Dashboard
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background">
            <div className="content-new">
              {items?.map((value, index) => {
                return (
                  <div
                    className="col-xl-3 col-sm-3 mb-xl-0 mb-3"
                    key={Math.random().toString()}
                  >
                    <div className="card">
                      <div className="card-header p-3 pt-2">
                        <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                          {value?.img}
                        </div>
                        <div className="text-end pt-1">
                          <p className="text-sm mb-0">{value?.users}</p>
                          <h4 className="mb-0 weight">{data[value?.total]}</h4>
                        </div>
                      </div>
                      <hr className="dark horizontal my-0" />
                      <div className="card-footer p-3">
                        <p className="mb-0">
                          <span className="text-success text-sm font-weight-bolder new"></span>
                          {}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div>{data?.length === 0 && <span>loading</span>}</div> */}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
