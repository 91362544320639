import { UserManagement, Userpurchase } from "../../../themes/ReduxConstant";

const instialValue = {
  isLoading: false,
  paginateData: {},
  Data: [],
};
export const userPurchaseReducer = (state = instialValue, action) => {
  switch (action.type) {
    case Userpurchase.USER_PURCHASE_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case Userpurchase.USER_PURCHASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Data: action.payload?.data,
        paginateData: action.payload?.paginateData,
      };
    case Userpurchase.USER_PURCHASE_FAIL:
      return {
        ...state,
        Data: [],
      };
    case UserManagement.ON_PAGE_LEAVE_LOAD:
      return {
        ...state,
        paginateData: {},
        data: [],
      };
    default:
      return state;
  }
};
