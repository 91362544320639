// export const SERVER_URL = "http://192.168.3.175:8000/api/v1/"; //local
// export const IMAGE_URL = "http://192.168.3.175:8000/";//local

// export const SERVER_URL = "http://1.6.98.141:8000/api/v1/";//public
// export const IMAGE_URL = "http://1.6.98.141:8000/"; //public

export const SERVER_URL = "https://api.sparkdatingapp.ca/api/v1/";//public
export const IMAGE_URL = "https://api.sparkdatingapp.ca/"; //public

export const Auth = {
  ON_LOGIN_LOAD: "ON_LOGIN_LOAD",
  ON_LOGIN_SUCCESS: "ON_LOGIN_SUCCESS",
  ON_LOGIN_FAIL: "ON_LOGIN_FAIL",
  ON_USER_LOGOUT: "ON_USER_LOGOUT",
  ON_FORGOTPASS_LOAD: "ON_FORGOTPASS_LOAD",
  ON_FORGOTPASS_SUCCESS: "ON_FORGOTPASS_SUCCESS",
  ON_FORGOTPASS_FAIL: "ON_FORGOTPASS_FAIL",
  ON_USER_LOGOUT_SUCCESS: "ON_USER_LOGOUT_SUCCESS",
  ON_USER_LOGOUT_FAIL: "ON_USER_LOGOUT_FAIL",
  ON_USER_LOGOUT_LOAD: "ON_USER_LOGOUT_LOAD",
  ON_PASSWORD_RESET_LOAD: "ON_PASSWORD_RESET_LOAD",
  ON_PASSWORD_RESET_SUCCESS: "ON_PASSWORD_RESET_SUCCESS",
  ON_PASSWORD_RESET_FAIL: "ON_PASSWORD_RESET_FAIL",
  ON_PASSWORD_CHANGE_LOAD: "ON_PASSWORD_CHANGE_LOAD",
  ON_PASSWORD_CHANGE_SUCCESS: "ON_PASSWORD_CHANGE_SUCCESS",
  ON_PASSWORD_CHANGE_FAIL: "ON_PASSWORD_CHANGE_FAIL",
  ON_LINK_VERIFY_LOAD: " ON_LINK_VERIFY_LOAD",
  ON_LINK_VERIFY_SUCCESS: " ON_LINK_VERIFY_SUCCESSS",
  ON_LINK_VERIFY_FAIL: "ON_LINK_VERIFY_FAIL",
  RESET: "RESET",
};
export const Dashboard = {
  REGISTER_USERS_DATA_LOAD: "REGISTER_USERS_DATA_LOAD",
  REGISTER_USERS_DATA_SUCCESS: "REGISTER_USERS_DATA_SUCCESS",
  REGISTER_USERS_DATA_FAIL: "REGISTER_USERS_DATA_FAIL",
};
export const UserManagement = {
  ON_USER_LOAD: "ON_USER_LOAD",
  ON_USER_SUCCES: "ON_USER_SUCCES",
  ON_USER_FAIL: "ON_USER_FAIL",
  ON_USER_DELETE_LOAD: "ON_USER_DELETE_LOAD",
  ON_USER_DELETE_SUCCESS: "ON_USER_DELETE_SUCCESS",
  ON_USER_DELETE_FAIL: "ON_USER_DELETE_FAIL",
  ON_USER_BLOCK_LOAD: "ON_USER_BLOCK_LOAD",
  ON_USER_BLOCK_SUCCESS: "ON_USER_BLOCK_SUCCESS",
  ON_USER_BLOCK_FAIL: "ON_USER_BLOCK_FAIL",
  ON_USER_VIEW_LOAD: "ON_USER_VIEW_LOAD",
  ON_USER_VIEW_SUCCESS: "ON_USER_VIEW_SUCCESS",
  ON_USER_VIEW_FAIL: "ON_USER_VIEW_FAIL",
  ON_USER_EDIT_LOAD: "ON_USER_EDIT_LOAD",
  ON_USER_EDIT_SUCCESS: "ON_USER_EDIT_SUCCESS",
  ON_USER_EDIT_FAIL: "ON_USER_EDIT_FAIL",
  ON_USER_SEARCH_LOAD: "ON_USER_SEARCH_LOAD",
  ON_USER_SEARCH_SUCCESS: "ON_USER_SEARCH_SUCCESS",
  ON_USER_SEARCH_FAIL: "ON_USER_SEARCH_FAIL",
  ON_PAGE_LEAVE_LOAD: "ON_PAGE_LEAVE_LOAD",
  ON_DROPDOWN_LOAD: "ON_DROPDOWN_LOAD",
  ON_DROPDOWN_SUCCESS: "ON_DROPDOWN_SUCCESS",
  ON_DROPDOWN_FAIL: "ON_DROPDOWN_FAIL",
};
export const Userpurchase = {
  USER_PURCHASE_LOAD: "USER_PURCHASE_LOAD",
  USER_PURCHASE_SUCCESS: "USER_PURCHASE_SUCCESS",
  USER_PURCHASE_FAIL: "USER_PURCHASE_FAIL",
};
export const Intrests = {
  INTRESTS_LOAD: "INTRESTS_LOAD",
  INTRESTS_SUCCESS: "INTRESTS_SUCCESS",
  INTRESTS_FAIL: "INTRESTS_FAIL",
};
export const fandomQuiz = {
  FANDOM_QUIZ_TOPIC_LOAD: "FANDOM_QUIZ_TOPIC_LOAD",
  FANDOM_QUIZ_TOPIC_SUCCESS: "FANDOM_QUIZ_TOPIC_SUCCESS",
  FANDOM_QUIZ_TOPIC_FAIL: "FANDOM_QUIZ_TOPIC_FAIL",

  FANDOM_QUIZ_TOPIC_DELETE_LOAD: "FANDOM_QUIZ_TOPIC_DELETE_LOAD",
  FANDOM_QUIZ_TOPIC_DELETE_SUCCESS: "FANDOM_QUIZ_TOPIC_DELETE_SUCCESS",
  FANDOM_QUIZ_TOPIC_DELETE_FAIL: "FANDOM_QUIZ_TOPIC_DELETE_FAIL",

  FANDOM_QUIZ_QUESTION_LOAD: "FANDOM_QUIZ_QUESTION_LOAD",
  FANDOM_QUIZ_QUESTION_SUCCESS: "FANDOM_QUIZ_QUESTION_SUCCESS",
  FANDOM_QUIZ_QUESTION_FAIL: "FANDOM_QUIZ_QUESTION_FAIL",

  FANDOM_QUIZ_QUESTION_DELETE_LOAD: "FANDOM_QUIZ_QUESTION_DELETE_LOAD",
  FANDOM_QUIZ_QUESTION_DELETE_SUCCESS: "FANDOM_QUIZ_QUESTION_DELETE_SUCCESS",
  FANDOM_QUIZ_QUESTION_DELETE_FAIL: "FANDOM_QUIZ_QUESTION_DELETE_FAIL",

  FANDOM_QUIZ_QUESTION_LIST_LOAD: "FANDOM_QUIZ_QUESTION_LIST_LOAD",
  FANDOM_QUIZ_QUESTION_LIST_SUCCESS: "FANDOM_QUIZ_QUESTION_LIST_SUCCESS",
  FANDOM_QUIZ_QUESTION_LIST_FAIL: "FANDOM_QUIZ_QUESTION_LIST_FAIL",

  FANDOM_QUIZ_QUESTION_ADD_LOAD: "FANDOM_QUIZ_QUESTION_ADD_LOAD",
  FANDOM_QUIZ_QUESTION_ADD_SUCCESS: "FANDOM_QUIZ_QUESTION_ADD_SUCCESS",
  FANDOM_QUIZ_QUESTION_ADD_FAIL: "FANDOM_QUIZ_QUESTION_ADD_FAIL",

  FANDOM_QUIZ_QUESTION_EDIT_LOAD: "FANDOM_QUIZ_QUESTION_EDIT_LOAD",
  FANDOM_QUIZ_QUESTION_EDIT_SUCCESS: "FANDOM_QUIZ_QUESTION_EDIT_SUCCESS",
  FANDOM_QUIZ_QUESTION_EDIT_FAIL: "FANDOM_QUIZ_QUESTION_EDIT_FAIL",
};
