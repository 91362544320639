import React from 'react';

import 'antd/dist/antd.css'
//dom
import ReactDOM from 'react-dom';

//components
import App from './App';

//redux
import { Provider } from 'react-redux';
import { Store, persistor } from './Redux/Store'

//PERSIST
import { PersistGate } from 'redux-persist/integration/react';

//css
import './index.css';

//routers
import { BrowserRouter } from 'react-router-dom';

//vitals
// import reportWebVitals from './reportWebVitals';

//react-toaster
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { appconstant } from './themes/appconstant';


ReactDOM.render(
  <Provider store={Store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
      <ToastContainer autoClose={appconstant.toastTimer} />
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
// reportWebVitals();
