import React, { useEffect } from "react";

//routers
import { Link } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";
import { loginAction } from "../../Redux/Actions/Auth";

//formik
import { Formik } from "formik";

//common
import { Textfiled } from "../../common";

//themes
import images from "../../themes/appImage";
import { appconstant } from "../../themes/appconstant";
import { validationSchema } from "../../utils/ValidationSchema";

const Login = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = appconstant.Titles.Login;
    window.history?.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history?.pushState(null, document.title, window.location.href);
    });
    return () => null;
  }, []);
  const onSubmit = (data) => {
    dispatch(loginAction(data));
  };
  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="line_true">
          <div className="content-e ">
            <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <h6 className="text-white text-capitalize ps-3 text-center">
                Login
              </h6>
            </div>
            <div className="text-center cost">
              <img src={images.logo}  />
            </div>
            <div className="wrapper_line">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema("login")}
                onSubmit={onSubmit}
              >
                {({ values, errors, handleChange, handleSubmit, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <Textfiled
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        variant="standard"
                        className="filed-control"
                        type="text"
                        name="email"
                        value={values.email.trim()}
                        onChangeText={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <Textfiled
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        type="password"
                        label="Password"
                        variant="standard"
                        className="filed-control aman"
                        name="password"
                        value={values.password.trim()}
                        onChangeText={handleChange}
                      />
                    </div>
                    <div className="forrgot">
                      <Link to="/forgotpassword">Forgot Password?</Link>
                    </div>

                    <div className="text-center_login">
                      <button type="submit" className="button-list">
                        Login
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
