import React, { useState, useEffect } from "react";

//UI
import { Layout, Menu } from "antd";

//routers
import { Link, useNavigate } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";
import { logoutAction } from "../Redux/Actions/Auth";

//themes
import images from "../themes/appImage";
//utils
import { navbar, navBarItems } from "../utils/navbar";
import { Model } from "../utils/model";

const { Sider } = Layout;

const Sidebar = () => {
  const [selecteditem, setselecteditem] = useState(null);
  const [Logout, setLogout] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    navbar((e) => {
      setselecteditem(e);
    });
  }, []);
  useEffect(() => {
    if (Logout) {
      onNavBarItemClick();
    }
  }, [Logout]);
  const onNavBarItemClick = (index, path) => {
    if (path) {
      navigate(path);
      setselecteditem(index);
    } else {
      !Logout &&
        index === 6 &&
        Model("Logout", "Are you sure, you want to logout?", (isClick) =>
          setLogout(isClick)
        );
      Logout && dispatch(logoutAction({ navigate }));
    }
  };
  return (
    <Sider className="sider_left new" collapsedWidth="0" width="265"  height="100vh">
      <div className="logo">
        <Link to="/dashboard">
          <img src={images.logo} alt=""/>
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[`${selecteditem}`]}
        items={navBarItems((index, path) => onNavBarItemClick(index, path))}
        key={Math.random().toString()}
        height={"100vh"}
      />
    </Sider>
  );
};
export default Sidebar;
