import { Intrests, UserManagement } from '../../../themes/ReduxConstant'
export const usersAction = (data) => {
    return {
        type: UserManagement.ON_USER_LOAD,
        payload:data
    }
}
export const usersDeleteAction = (data) => {
    return {
        type: UserManagement.ON_USER_DELETE_LOAD,
        payload: data
    }
}
export const usersBlockAction = (data) => {
    return {
        type: UserManagement.ON_USER_BLOCK_LOAD,
        payload: data
    }
}
export const viewUserAction = (data) => {
    return {
        type: UserManagement.ON_USER_VIEW_LOAD,
        payload: data
    }
}
export const editUserAction = (data) => {
    return {
        type: UserManagement.ON_USER_EDIT_LOAD,
        payload: data
    }
}
export const searchAction = (data) => {
    return {
        type: UserManagement.ON_USER_SEARCH_LOAD,
        payload: data
    }
}
export const RemovePaginationData = () => {
    return {
        type: UserManagement.ON_PAGE_LEAVE_LOAD,
    }
}
export const dropdownListAction = () => {
    return {
        type: UserManagement.ON_DROPDOWN_LOAD,
    }
}
export const InterestsAction = () => {
    return {
      type:Intrests.INTRESTS_LOAD
    };
  };
  
