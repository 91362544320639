import { appconstant } from "../../themes/appconstant";
import { Model } from "../../utils/model";
import { Tooltip } from "antd";
export const columns = (cb, Userid, currentPage) => {
  return [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) =>
        currentPage > 1 ? (currentPage - 1) * 10 + (index + 1) : index + 1,
    },
    {
      title: "Category Type",
      dataIndex: "topic",
      key: "topic",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item) => {
        return (
          <div>
            <button
              type="submit"
              onClick={() => Userid({ id: item, name: appconstant.edit })}
            >
              {appconstant.edit}
            </button>
            <button
              type="submit"
              onClick={() =>
                Model(
                  appconstant.Delete_Title,
                  appconstant.QuizDelete_Text,
                  (isClick, name) => cb(isClick, name, item)
                )
              }
            >
              {appconstant.Delete_Title}
            </button>
          </div>
        );
      },
    },
  ];
};

export const TabelHead = (cb, Userid, currentPage, isEdit) => {
  return [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      width: "50px",
      render: (value, item, index) => {
        return (
          <div style={{ width: "50px" }}>
            {currentPage > 1 ? (currentPage - 1) * 10 + (index + 1) : index + 1}
          </div>
        );
      },
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      sorter: true,
      width: "200px",

      render: (value, item, index) => {
        return (
          <Tooltip title={item?.question} className="Questions">
            <div className="tabelDiv tabelColume">{item.question ?? "N/A"}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "Option A ",
      dataIndex: "options",
      key: "options",
      sorter: true,
      width: "200px",

      render: (value, item, index) => {
        return (
          <Tooltip title={item?.options[0]} className="Questions">
            <div className="tabelDiv tabelColume">
              {item?.options[0] ?? "N/A"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Option B",
      dataIndex: "options",
      key: "options",
      sorter: true,
      width: "200px",
      render: (value, item, index) => {
        return (
          <Tooltip title={item?.options[1]} className="Questions">
            <div className="tabelDiv tabelColume">
              {item?.options[1] ?? "N/A"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Option C",
      dataIndex: "options",
      key: "options",
      sorter: true,
      width: "200px",
      render: (value, item, index) => {
        return (
          <Tooltip title={item?.options[2]} className="Questions">
            <div className="tabelDiv tabelColume">
              {item?.options[2] ?? "N/A"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Option D",
      dataIndex: "options",
      key: "options",
      sorter: true,
      width: "200px",
      render: (value, item, index) => {
        return (
          <Tooltip title={item?.options[3]} className="Questions">
            <div className="tabelDiv tabelColume">
              {item?.options[3] ?? "N/A"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Correct  Option",
      dataIndex: "correctOption",
      key: "correctOption",
      sorter: true,
      width: "120px",
      render: (value, item, index) => {
        return <div style={{ width: "120px" }}>{item?.correctOption}</div>;
      },
    },

    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item, index) => {
        return (
          <div>
            {
              <button
                type="submit"
                onClick={() => Userid({ id: item, name: appconstant.edit })}
              >
                {appconstant.edit}
              </button>
            }
            <button
              type="submit"
              onClick={() =>
                Model(
                  appconstant.Delete_Title,
                  appconstant.Delete_QUESTION_text,
                  (isClick, name) => cb(isClick, name, item)
                )
              }
            >
              {appconstant.Delete_Title}
            </button>
          </div>
        );
      },
    },
  ];
};
