import { Modal } from "antd";

export const Model = (title, text, isClick) => {
  Modal.confirm({
    title: title,
    content: text,
    okText: "Yes",
    centered: true,
    cancelText: "No",
    onOk() {
      isClick(true, title);
    },
    onCancel() {
      isClick(false, title);
    },
    className: "new-button",
  });
};
